const initialState:any = [];
export const SAVE_CARDS = 'SAVE_CARDS'
export interface ContactCards{
    contactCards: Array<ContactCard>
};
export interface ContactCard{
    contactCard:any
};
export interface SaveCardAction{
    type: typeof SAVE_CARDS
    payload: ContactCards
};

  // Use the initialState as a default value
export default function contactCardReducer(state:any = initialState, action:SaveCardAction) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_CARDS':
            return action.payload.contactCards;
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}