import React from 'react';

interface CallToProps {
    phone:string,
}

const CallTo: React.FC<CallToProps> = ({ phone, children }) => {
    return <a className="sg-text-current sg-w-9/12" style={{textDecoration:'inherit'}} href={`tel:${phone}`}>{children}</a>;
};

export default CallTo;