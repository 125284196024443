import deviceStatus from './DeviceStatusReducer'
import contactCardReducer from './ContactCardReducer'
import formsReducer from './FormsReducer'
import historyReducer from './HistoryReducer'
import loginReducer from './LoginReducer'
import notificationReducer from './NotificationsReducer'
import resultsReducer from './ResultsReducer'
import settingsReducer from './SettingsReducer'
import toursReducer from './ToursReducer'
import userReducer from './UserReducer'
//import submisionQueueReducer from './SubmisionQueueReducer'

const initialState = {
    deviceStatus:{
      connection:{
        connectionType:'none',
        connected:false,
      }
    },
    userAuth:{ 
        authToken: '',
        userName: '',
    },
    settings:{ 
        theme: 'light',
    },
    tours: [],
    forms: [],
    contactCards:[],
    notifications:{
      notificationsData:[],
      totalNotificationCount:0,
      checkedNotificationCount:0,
    },
    history:[],
    user:{
      name:'',
      country:'',
      phone:'',
      roles:[],
    },
    //submisionQueue:[],
}

export default function rootReducer(state:any = initialState, action:any) {
  // always return a new object for the root state
  switch (action.type) {
    case 'WIPE_STATE':
        console.log(action);
        return initialState;
    default:
      return {
        deviceStatus: deviceStatus(state.deviceStatus, action),
        userAuth: loginReducer(state.userAuth, action),
        // For both reducers, we only pass in their slice of the state
        settings: settingsReducer(state.settings, action),
        tours: toursReducer(state.tours, action),
        forms: formsReducer(state.forms, action),
        contactCards: contactCardReducer(state.contactCards, action),
        notifications: notificationReducer(state.notifications, action),
        history: historyReducer(state.history, action),
        results: resultsReducer(state.results, action),
        user: userReducer(state.user, action),
        //submisionQueue: submisionQueueReducer(state.submisionQueue, action),
        }
    }
}