const initialState:any = []

export const SAVE_HISTORY = 'SAVE_HISTORY'
export interface History{
    history: Array<any>
};
export interface HistoryActions{
    type: typeof SAVE_HISTORY
    payload: History
};


  // Use the initialState as a default value
export default function historyReducer(state:any = initialState, action:HistoryActions) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case 'SAVE_HISTORY':
            console.log(action);
            
            return action.payload.history;
        // Do something here based on the different types of actions
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}