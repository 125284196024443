import React,{ useEffect, useState, } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonMenuButton, IonBackButton, IonButtons, IonRefresher, IonRefresherContent, IonImg } from '@ionic/react';
import FullscreenLoader from '../../components/FullscreenLoader';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useNotifs } from '../../hooks/useNotifs';
import { useDispatch } from 'react-redux';
import NotificationItemLine from '../../components/notifiaction/NotificationItemLine';


const Notifs: React.FC = () => {
    const {reqUseruseNotifs} = useNotifs();
    /* const [notifs, setNotifs] = useState<any>(
        [
            {
                name:'',
                message:''
            },
            {
                name:'',
                message:''
            },
    ]); */
    // let notifs = useSelector((state:any) => state.notifications.notificationsData)
    let dispatch = useDispatch();

    const [fresh, setFresh] = useState(true);
    const [loading, setLoading] = useState(false);
    const [notifs, setNotifs] = useState([]);
    const [mounted, setMounted] = useState(false)

    if(!mounted){
        reqUseruseNotifs().then((notifsData)=>{
            setNotifs(notifsData);
            console.log(notifsData);
            let seenCount = 0;
            for (let notif of notifsData) {
                if (notif.seen) seenCount++;
            }
            console.log(seenCount);
            dispatch({type:'SAVE_NOTIFS', payload:{notifications:notifsData}})
            dispatch({type:'SAVE_CHECKED_NOTIF_COUNT', payload:{seenCount}});
        })
        .catch((error)=> {
            console.log(error);
        });
    }

    useEffect(() =>{
        setMounted(true)
    },[])

    useEffect(()=>{
        if(fresh){
            setLoading(true);
            if(!notifs.length){
                doRefresh();
            }
            setLoading(false);
            setFresh(false);
        }
    }, [setFresh, fresh]);

    const doRefresh = (event:any=null) => {
        reqUseruseNotifs().then((notifsData)=>{
            setNotifs(notifsData);
            console.log(notifsData);
            let seenCount = 0;
            for (let notif of notifsData) {
                if (notif.seen ) seenCount++;
            }
            console.log(seenCount);
            dispatch({type:'SAVE_NOTIFS', payload:{notifications:notifsData}})
            dispatch({type:'SAVE_CHECKED_NOTIF_COUNT', payload:{seenCount} });
            //setContacts(cardsData);
            if(event){
                event.detail.complete();
            }
        })
        .catch((error)=> {
            //setValidations(error);
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }

    /* let contacts = Array.apply(null, Array(8)).map(function (x, i) { 
        return {
            name:'James Alberts',
            title: 'Survey Director',
            tel:'(303) 555-0105', 
            email:'jame.alberts@example.com',
            photo_path:process.env.REACT_APP_BASE_URL + '/img/YrRqcygTfho.jpg', 
            font:0,
            country:'México',
            description:'Enim labore Lorem occaecat anim mollit voluptate.'
        }; 
    }); */

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    {/* <IonTitle size="large">Notificaciones</IonTitle> */}
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Notificaciones</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                    <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Tire para refrescar"
                    refreshingSpinner="circles"
                    refreshingText="Refrescando...">
                    </IonRefresherContent>
                </IonRefresher>
                <div className="sg-text-center sg-flex sg-flex-col sg-w-full sg-items-center sg-divide-x-0 sg-divide-y-2 sg-divide-sgSecondary sg-divide-solid sg-divide-opacity-80">
                    {notifs ? notifs.map((notif:any) =>
                    // <NotificationItem title={notif.title} key={notif.title}>{<p className={"sg-my-2 sg-text-gray-500 sg-text-base sg-min-h-6 sg-min-w-full " + (notif.message ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{notif.message}</p>}</NotificationItem>
                    <NotificationItemLine notif={notif}></NotificationItemLine>
                    // <div className="sg-w-full sg-pt-8 sg-text-left">
                    //     <div className="sg-w-full">
                    //         <div className="sg-w-full pr-1">
                    //             <Collapsible transitionTime={300} easing="ease-out" trigger={
                    //                 <div className="sg-flex sg-flex-row sg-w-72">
                    //                     <span className={"sg-text-sgBlue sg-text-2xl sg-no-underline sg-font-bold sg-min-h-6 sg-min-w-full sg-block sg-mb-2" + (notif.title ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{notif.title}</span>
                    //                     <span className="sg-my-2 sg-text-sgBlue sg-text-2xl">view</span>
                    //                 </div>
                    //             }>
                    //                 <p className={"sg-my-2 sg-text-gray-500 sg-text-base sg-min-h-6 sg-min-w-full" + (notif.message ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{notif.message}</p>
                    //             </Collapsible>
                    //         </div>
                    //     </div>
                    // </div>
                    )
                    : <FullscreenLoader/>}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Notifs;
