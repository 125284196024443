import React,{ useEffect, useState, } from 'react';
import { IonContent, IonFooter, IonHeader, IonIcon, IonImg, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonBadge, useIonViewDidEnter, useIonViewWillEnter, } from '@ionic/react';
import './Home.css';
import TourCard from '../../components/tours/TourCard';
import { notifications, notificationsOutline} from 'ionicons/icons';
import { chevronDownCircleOutline, create, createOutline } from 'ionicons/icons';
import { useProfile } from '../../hooks/useProfile';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Haptics, HapticsImpactStyle } from '@capacitor/haptics';
import { DEFUALT_PFP_PATH } from '../../helpers/ImagePaths';

const Home: React.FC = () => {
    const { reqSelfProfile, reqMyTours } = useProfile();
    //const [loading, setLoading] = useState(false);
    const [fresh, setFresh] = useState(true);
    const toursRedux = useSelector((state:any) => { return state.tours});
    const userRedux = useSelector((state:any) => { return state.user});
    const dispatch = useDispatch();


    //const deviceStatus = useSelector((state:any) => { return state.deviceStatus});

    useIonViewWillEnter(() => {
        reqSelfProfile().then(resp => {
            //console.log(resp.__meta__.resNotifications_count);
            //console.log(resp);
            //setUser(resp);
            dispatch({ type: 'SAVE_USER_INFO', payload:{
                user:{
                    id:resp.id ? resp.id : '' ,
                    name:resp.name ? resp.name : '' ,
                    email:resp.email ? resp.email : '' ,
                    country:resp.country ? resp.country : '' ,
                    phone:resp.phone ? resp.phone : '' ,
                    roles:resp.roles ? resp.roles : [] ,
                    status:resp.status ? resp.status : '' ,
                    pfp_path:resp.pfp_path ? resp.pfp_path : '' ,
                    username:resp.username ? resp.username : '' ,

                }
            } });
            dispatch({ type: 'SAVE_TOTAL_NOTIF_COUNT', payload:{count:resp.__meta__.resNotifications_count} });
            dispatch({type: 'SAVE_CHECKED_NOTIF_COUNT', payload:{count: resp.__meta__.seenNotifications_count}});
        }).catch(error => console.log(error));
        reqMyTours().then((resp) => {
            setTours(resp)
            //console.log(resp);
            dispatch({ type: 'SAVE_TOURS', payload:{tours:resp} });
            //console.log(toursRedux);
        }).catch(error => console.log(error));
    });

    useIonViewDidEnter(() => {
        reqSelfProfile().then(resp => {
            console.log(resp.__meta__.resNotifications_count);
            //console.log(resp);
            //setUser(resp);
            dispatch({ type: 'SAVE_USER_INFO', payload:{
                user:{
                    id:resp.id ? resp.id : '' ,
                    name:resp.name ? resp.name : '' ,
                    email:resp.email ? resp.email : '' ,
                    country:resp.country ? resp.country : '' ,
                    phone:resp.phone ? resp.phone : '' ,
                    roles:resp.roles ? resp.roles : [] ,
                    status:resp.status ? resp.status : '' ,
                    pfp_path:resp.pfp_path ? resp.pfp_path : '' ,
                    username:resp.username ? resp.username : '' ,

                }
            } });
            dispatch({ type: 'SAVE_TOTAL_NOTIF_COUNT', payload:{count:resp.__meta__.resNotifications_count} });
            dispatch({type: 'SAVE_CHECKED_NOTIF_COUNT', payload:{count: resp.__meta__.seenNotifications_count}});
        }).catch(error => console.log(error));
        reqMyTours().then((resp) => {
            setTours(resp)
            //console.log(resp);
            dispatch({ type: 'SAVE_TOURS', payload:{tours:resp} });
            //console.log(toursRedux);
        }).catch(error => console.log(error));
    });

    /* const [user, setUser] = useState<ProfileObject>({
        username:'',
        pfp_path:'',
        name:'',
        country:'',
        phone:'',
        roles:[],
        __meta__:[],
    }); */
    const [tours, setTours]:any = useState([]);

    // useEffect(() =>{
    //     setMounted(true)
    // },[])

    useEffect(()=>{
        if(fresh){
            doRefresh();
            setFresh(false);
        }
    }, [setFresh, fresh, reqSelfProfile, reqMyTours, tours, dispatch]);


    const doRefresh = (event:any = null) => {
        reqSelfProfile().then(resp => {
            //console.log(resp);
            //setUser(resp);
            dispatch({ type: 'SAVE_USER_INFO', payload:{
                user:{
                    id:resp.id ? resp.id : '' ,
                    name:resp.name ? resp.name : '' ,
                    email:resp.email ? resp.email : '' ,
                    country:resp.country ? resp.country : '' ,
                    phone:resp.phone ? resp.phone : '' ,
                    roles:resp.roles ? resp.roles : [] ,
                    status:resp.status ? resp.status : '' ,
                    pfp_path:resp.pfp_path ? resp.pfp_path : '' ,
                    username:resp.username ? resp.username : '' ,

                }
            } });
            dispatch({ type: 'SAVE_TOTAL_NOTIF_COUNT', payload:{count:resp.__meta__.resNotifications_count} });
            dispatch({type: 'SAVE_CHECKED_NOTIF_COUNT', payload:{count: resp.__meta__.seenNotifications_count}});
        }).catch(error => console.log(error));
        reqMyTours().then((resp) => {
            setTours(resp)
            //console.log(resp);
            dispatch({ type: 'SAVE_TOURS', payload:{tours:resp} });
            //console.log(toursRedux);
            if(event){
                event.detail.complete();
            }
        }).catch(error => {
            console.log(error)
            if(event){
                event.detail.complete();
            }
        });
    }

    console.log(userRedux.pfp_path);
    let lastNotifCount = 0;
    const notifCount = useSelector((state:any) => {
        //console.log(state);
        let v = state.notifications.totalNotificationCount - state.notifications.checkedNotificationCount;
        if( v > lastNotifCount ){
            Haptics.notification(); //this is an async function despite the fact that im calling it with out a then() or await. i just dont need to do anything after it so just call it like this is fine
        }
        lastNotifCount = v;
        return v;
    });
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {/* <IonItem className="notifs_button" routerLink="/notifs" routerDirection="none" lines="none" detail={false}> */}
                    <Link className="notifs_button" to="/notifs">
                    <IonIcon className="sg-text-sgSecondary notif_icon" slot="start" ios={notificationsOutline} md={notifications}/>
                        {true && //change, also gotta link to page
                            <IonBadge color="danger" className={notifCount ? 'notifs' : 'sg-hidden'}>{notifCount}</IonBadge> //change again
                        }
                    </Link>
                        
                    {/* </IonItem> */}
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>

                    <IonMenuButton className="drawer_icon" slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Giras activas</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                    <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Tire para refrescar"
                    refreshingSpinner="circles"
                    refreshingText="Refrescando...">
                    </IonRefresherContent>
                </IonRefresher>
                <Link to="/user/self" className="sg-w-full sg-flex sg-p-4 sg-space-x-6 sg-no-underline">
                    <div className="sg-profile-image sg-h-20 sg-w-20">
                        <img className="sg-profile-image-display sg-h-20 sg-w-20 sg-text-center sg-rounded-full" alt="" src={userRedux.pfp_path ? (userRedux.pfp_path.startsWith('https') ? userRedux.pfp_path : process.env.REACT_APP_BASE_URL + '/' + userRedux.pfp_path) : DEFUALT_PFP_PATH}/>
                        <div className="sg-profile-image-upload sg-text-center sg-rounded-full sg-shadow-lg sg-bg-gray sg-text-gray-500 sg-bg-gray-300 sg-h-9 sg-w-9 sg--bottom-0 sg--right-2">
                            <IonIcon className="sg-h-6 sg-w-6 sg-pl-1 sg-absolute sg-top-1/2 sg-left-1/2 sg-transform sg--translate-x-1/2 sg--translate-y-1/2" ios={createOutline} md={create}/>
                        </div>
                    </div>
                    <div className="sg-h-20 sg-pt-6 sg-flex sg-flex-col sg-justify-center sg-text-gray-600 sg-leading-normal">
                        <span className="sg-font-semibold sg-text-lg">{userRedux ? userRedux.name : ''}</span>
                        <span className="sg-font-light">PERFIL</span>
                    </div>
                </Link>
                { toursRedux && toursRedux.map( (tour:any) => <TourCard tour={tour} key={tour.id}></TourCard> ) }
                {(toursRedux && toursRedux.length <= 0) && 
                    <div className="sg-flex sg-justify-center sg-items-center sg-flex-col sg-pt-24">
                        <h3 className="sg-text-gray-700">No hay giras disponibles.</h3>
                        <IonImg src={'/assets/images/syngenta_logo_black.png'} className="sg-h-14 sg-w-60 sg-opacity-50"/>
                    </div>
                }
                <div className="bottom_space"></div>
            </IonContent>
            <IonFooter className="ion-no-border sg-overflow-visible">
                <div className="sg-rounded-t-xl sg-shadow-lg sg-mx-auto sg-w-11/12 sg-h-16 sg-bg-gray-100 sg-overflow-visible sg-relative">
                    <IonImg className="sg-pl-4 sg-pt-2" src={'/assets/images/syngenta_logo.png'} alt="logo" style={{width:'90px'}}/>
                    <Link className="sg-block sg-rounded-full sg-shadow-lg sg-w-14 sg-h-14 sg-bg-gradient-to-tl sg-from-sgPrimary sg-to-sgSecondary sg-absolute sg-top-0 sg-right-7 sg-transform sg--translate-y-1/2 sg-text-white sg-z-50" to="/tours_add">
                        <IonImg className="sg-absolute sg-top-1/2 sg-left-1/2 sg-transform sg--translate-x-1/2 sg--translate-y-1/2 sg-h-6" src={"/assets/icon/plus.svg"} />
                    </Link>
                    <p className="sg-text-sm sg-uppercase sg-text-center sg-font-medium sg-w-32 sg-text-gray-400 sg-absolute sg-bottom-1 sg-right-0 sg-my-0 sg-leading-4">Agrega Código de invitación</p>
                </div>
            </IonFooter>
            {/* <FullscreenLoader/> */}
        </IonPage>
    );
};

export default Home;
