import React,{ useState, } from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
//import ExploreContainer from '../../components/ExploreContainer';
import './Settings1.css';

import { useDarkMode } from '../../hooks/useDarkMode';

const Settings1: React.FC = () => {
    const { toggleDarkTheme, getDarkState } = useDarkMode();
    const [checked, setChecked] = useState(getDarkState());
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonTitle>Ajustes</IonTitle>
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {/* <IonItem>
                    <IonLabel>Tema: {checked ? 'Oscuro' : 'Claro'}</IonLabel>
                    <IonToggle checked={checked} onIonChange={(e) => {setChecked(toggleDarkTheme(e.detail.checked))}} />
                </IonItem> */}
                {/* <ExploreContainer name="Settings Page" /> */}
            </IonContent>
        </IonPage>
    );
};

export default Settings1;
