import { FC } from "react";
import SgInput from "./sg_form_inputs/SgInput";
import SgTextarea from "./sg_form_inputs/SgTextarea";
import SgInputCheckBox from "./sg_form_inputs/SgInputCheckBox";
import SgRadioGroup from "./sg_form_inputs/SgRadioGroup";

export interface SgFormFieldProps {
    field:any
    formIndex:number;
    pageIndex:number;
    componentIndex:number; 
    stageIndex:number;
    plotIndex:number;
    is_satisfaction:any;
    descripcion:any;

}

const SgFormField: FC<SgFormFieldProps> = ({
    field,
    formIndex,
    pageIndex,
    componentIndex,
    stageIndex,
    plotIndex,
    is_satisfaction,
    descripcion,
    }) => {
        if(field.type==="radio" || field.type==="radioPie" || field.type==="radioQuantitative"){
            return(
                <SgRadioGroup
                field={field}
                formIndex={formIndex}
                pageIndex={pageIndex}
                componentIndex={componentIndex}
                stageIndex={stageIndex}
                plotIndex={plotIndex}
                is_satisfaction={is_satisfaction}
                descripcion={descripcion}
                key={field.key}
                //value={field.userInput && field.userInput.stages && field.userInput.stages[stageIndex] ? field.userInput.stages[stageIndex][plotIndex] : ''}
                />
            );
        }else if(field.type==="selectboxes" || field.type==="selectboxesPie"){
            return(
                <div className="sg-px-4 sg-text-left" key={field.key}>
                    <label htmlFor={field.key}>{field.label}</label>
                    <div className="sg-w-full sg-grid sg-grid-cols-2 sg-gap-4">
                    { field.values.map((checkBox:any, optionIndex:number) => {
                        return(
                            <SgInputCheckBox
                            field={field}
                            checkBox={checkBox}
                            formIndex={formIndex}
                            pageIndex={pageIndex}
                            componentIndex={componentIndex}
                            optionIndex={optionIndex}
                            stageIndex={stageIndex}
                            plotIndex={plotIndex}
                            is_satisfaction={is_satisfaction}
                            descripcion={descripcion}
                            />
                        );
                    })}
                    </div>
                </div>
            );
        }else if(field.type==="textarea"){
            return(
                <SgTextarea
                id={field.key}
                field={field}
                formIndex={formIndex}
                componentIndex={componentIndex}
                pageIndex={pageIndex}
                autoGrow={true}
                className=" sg-placeholder-gray-600 sg-px-2 sg-w-full rounded-xl h-12 px-4 sg-bg-transparent sg-appearance-none sg-border-black sg-border sg-border-solid sg-rounded-xl focus-within:sg-border-sgBlue focus-within:sg-border-2"
                maxlength={field.validate && field.validate.maxLength}
                stageIndex={stageIndex}
                plotIndex={plotIndex}
                is_satisfaction={is_satisfaction}
                descripcion={descripcion}
                enterkeyhint="enter"
                key={field.key}
                value={field.userInput && field.userInput.stages && field.userInput.stages[stageIndex] && field.userInput.stages[stageIndex][plotIndex] ? field.userInput.stages[stageIndex][plotIndex].value : ''}
              />
            );
        }else{
            return(
                <SgInput
                field={field}
                id={field.key}
                formIndex={formIndex}
                componentIndex={componentIndex}
                pageIndex={pageIndex}
                className=" sg-placeholder-gray-600 sg-px-2 sg-w-full rounded-xl h-12 px-4 sg-bg-transparent sg-appearance-none sg-border-black sg-border sg-border-solid sg-rounded-xl focus-within:sg-border-sgBlue focus-within:sg-border-2"
                type={(field.type==='numberPie' || field.type==='number') ?  field.type : 'text'}
                inputmode={(field.type==='numberPie' || field.type==='number') ? 'decimal' : ''}
                value={field.userInput && field.userInput.stages && field.userInput.stages[stageIndex] && field.userInput.stages[stageIndex][plotIndex] ? field.userInput.stages[stageIndex][plotIndex].value : ''}
                stageIndex={stageIndex}
                plotIndex={plotIndex}
                is_satisfaction={is_satisfaction}
                descripcion={descripcion}
                enterkeyhint={(field.type==='numberPie' || field.type==='number') ? 'done' : 'done'}
                key={field.key}
                />
            );
        }
};

export default SgFormField;
