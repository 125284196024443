import React,{ useState, } from 'react';
import { IonBackButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonImg, useIonViewDidEnter} from '@ionic/react';
import { useTours } from '../../hooks/useTours';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FullscreenLoader from '../../components/FullscreenLoader';
import { DEFUALT_TOUR_IMG_PATH } from '../../helpers/ImagePaths';
/* 
import {
    StaticGoogleMap,
    Marker,
    Path,
} from 'react-static-google-map';
 */
const TourDetials: React.FC = () => {
    let { id }:any = useParams();
    const { reqTourInfo } = useTours();
    /* const { reqTour } = useTours();
    reqTour(id); */
    let [tour, setTour] = useState<any>(null);

    let [contacts, setContacts] = useState<any>(null);

    const tourRedux:any = useSelector((state:any) => { 
        //console.log(state.tours);
        if(state.tours){
            for (const tourItem of state.tours) {
                //console.log(id);
                //console.log(tourItem.id);
                if(tourItem.id == id){
                    return tourItem;
                }
            }
        }
    });

    // const { isLoaded } = useJsApiLoader({
    //     id: 'google-map-script',
    //     googleMapsApiKey: "AIzaSyDlJ9hLkE9-c4Ir3dwTqCo6onISyrI4fCA",
    //     language: "es-ES"
    //   })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const containerStyle = {
        width: '100%',
        height: '300px'
    };

    useIonViewDidEnter(() => {
        reqTourInfo(id).then(resp => {
            //console.log(resp)
            let cords = JSON.parse(resp.cords)
            setTour({
                id: resp.id,
                name: resp.name,
                feature_image: resp.feature_image,
                tour_form_id: resp.tour_form_id,
                coordinates: resp.cords ? cords : 'Desconocidas',
                date: resp.date
            });
            setContacts(resp.contacts);
        }).catch(error => console.log(error));
    });

    return (
        <IonPage>
            { tour && contacts ?
            <div>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" text="Atrás"/>
                        </IonButtons>
                        <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                        {/* <IonTitle>{tourRedux.name}</IonTitle> */}
                        <IonMenuButton slot="end"></IonMenuButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Información de la Gira</IonTitle>
                        </IonToolbar>
                    </IonHeader>

                    <IonCard className="sg-overflow-visible">
                        <img className="tour-card-img sg-rounded-t-lg" src={ tour.feature_image ? process.env.REACT_APP_BASE_URL + '/' + tour.feature_image : DEFUALT_TOUR_IMG_PATH } alt={tour.name + ' image'} />
                        <div className="sg-px-4">
                            <IonCardHeader className="sg-text-center sg-border-gray-400 sg-border-b sg-border-0 sg-border-solid">
                                <strong className="sg-text-sgBlue sg-text-xl sg-no-underline">{tour.name}</strong>
                            </IonCardHeader>
                        </div>
                        <IonCardContent>
                            <div>
                                <div className="sg-pb-4 sg-flex sg-flex-col sg-text-gray-500">
                                <div className="sg-list-none sg-text-center sg-px-0">
                                        <li>Fecha</li>
                                        <strong className="sg-text-sgBlue">{tour.date}</strong>
                                        <div className="sg-h-2"></div>
                                        <li>Coordenadas</li>
                                        <strong className="sg-text-sgBlue ">Lat: {tour.coordinates.lat}</strong>
                                        <br></br>
                                        <strong className="sg-text-sgBlue ">Long: {tour.coordinates.lng}</strong>
                                </div>
                                    {/* <div className="sg-w-full sg-h-36 sg-bg-gray-100 sg-rounded-2xl"></div> */}
                                </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                    <div className="sg-flex sg-flex-wrap sg-justify-center">
                        <div className="sg-w-full sg-sm:w-full sg-px-4">
                            <img src={`https://maps.googleapis.com/maps/api/staticmap?size=400x250&zoom=12&scale=1&format=png&maptype=roadmap&language=es-ES&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&markers=size:normal%7Ccolor:red%7Clabel:Localizaci%C3%B3n%7C${tour.coordinates.lat},${tour.coordinates.lng}`} alt="..." className="sg-shadow-lg sg-rounded-lg sg-max-w-full sg-h-auto sg-align-middle sg-border-none" />
                        </div>
                    </div>
                    {<IonCard>
                        <div className="sg-px-4">
                        <IonCardHeader className="sg-text-center sg-border-gray-400 sg-border-b sg-border-0 sg-border-solid">
                                <strong className="sg-text-sgBlue sg-text-xl sg-no-underline">Contactos</strong>
                        </IonCardHeader>
                        </div>
                        <ul className="sg-list-none sg-text-center sg-px-0">
                        {contacts.map((contact:any) => <div className="sg-my-4" key={contact.id}>
                                <li>Productor <strong className="sg-text-sgBlue sg-leading-5">{contact.name}</strong></li>
                                <li>Teléfono <strong className="sg-text-sgBlue sg-leading-5">{contact.phone_number}</strong></li>
                                <li>Email <strong className="sg-text-sgBlue sg-leading-5">{contact.email}</strong></li></div>
                        )}
                        </ul>
                    </IonCard>}
                </IonContent>
            </div>
            : <FullscreenLoader/> }
        </IonPage>
    );
};

export default TourDetials;
