const initialState:any = [];

export const SAVE_TOURS = 'SAVE_TOURS'
export const ADD_TOUR = 'ADD_TOUR'
export interface Tours{
    tours: Array<Tour>
};
export interface Tour{
    tour:any
};
export interface SettingsActions{
    type: typeof SAVE_TOURS
    payload: Tours
};
export interface TourAddAction{
    type: typeof ADD_TOUR
    payload: Tour
};


  // Use the initialState as a default value
export default function toursReducer(state:any = initialState, action:SettingsActions|TourAddAction) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_TOURS':
            return action.payload.tours;
        case 'ADD_TOUR':
            return [action.payload.tour, ...state];
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}