
import { IonButton, IonIcon } from '@ionic/react';
import { warningOutline } from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';

const RefreshConfirmModal: React.FC<{
    handleModalAccept: () => void;
    handleModalDismiss: () => void;
}> = ({ handleModalDismiss, handleModalAccept }) => (
    <div className="sg-h-full sg-text-center sg-flex sg-flex-col sg-items-center sg-justify-around">
        <div className="sg-flex-grow">
            <IonIcon icon={warningOutline} className="sg-text-2xl sg-h-32 sg-w-full sg-text-red-400"></IonIcon>
            <span className="sg-text-xl sg-leading-6 sg-text-gray-600 sg-font-medium">Recargar este formulario borrará las respuestas no enviadas. ¿Deseas continuar?</span>
        </div>
        <div className="sg-flex sg-flex-wrap sg-h-20 sg-w-full sg-p-2">
            <IonButton className="sg-h-12 sg-w-1/2 sg-m-0 sg-p-1" color="medium" fill="outline" onClick={() => handleModalDismiss()}>Cancelar</IonButton>
            <IonButton className="sg-h-12 sg-w-1/2 sg-m-0 sg-p-1" onClick={() => handleModalAccept()}>Continuar</IonButton>
        </div>
    </div>
);

export default RefreshConfirmModal;