const initialState = {
    theme: 'light',
}

export const SAVE_SETTING = 'SAVE_SETTING'
export interface Setting{
    settingValue: string
};
export interface SettingsActions{
    type: typeof SAVE_SETTING
    payload: Setting
};


  // Use the initialState as a default value
export default function settingsReducer(state:any = initialState, action:SettingsActions) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
      // Do something here based on the different types of actions
    default:
        // If this reducer doesn't recognize the action type, or doesn't
        // care about this specific action, return the existing state unchanged
        return state
    }
}