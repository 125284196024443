const initialState = {
    connection:{
        connectionType:'none',
        connected:false,
    }
}


export const SAVE_CONECTION_DATA = 'SAVE_CONECTION_DATA'
export interface CompleteStatus{
    connection:Conntection
};
export interface Conntection{
    connectionType: string
    connected: boolean
};
export interface DeviceStatusActions{
    type: typeof SAVE_CONECTION_DATA
    payload: Conntection
};


export default function deviceStatusReducer(state:any = initialState, action:DeviceStatusActions) {
    switch (action.type) {
        case 'SAVE_CONECTION_DATA':
            return {...state, connection:action.payload};
        default:
            return state
    }
}