const initialState = {
    name:'',
    country:'',
    phone:'',
    roles:[],
}

export const SAVE_USER_INFO = 'SAVE_USER_INFO'
export interface Setting{
    user: any
};
export interface UserInfoActions{
    type: typeof SAVE_USER_INFO
    payload: Setting
};


  // Use the initialState as a default value
export default function userReducer(state:any = initialState, action:UserInfoActions) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_USER_INFO':
                //console.log(action);
                return action.payload.user;
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}