import { useHttpReq } from './useHttpReq';
import { GetResult, Storage } from '@capacitor/storage';

export function useForms() {
    const { makeHttpGet, makeHttpPost } = useHttpReq();

    const reqTourForm = async (tour_id:number, form_id:number):Promise<any> => { 
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let resp = await makeHttpGet(`/tours/${tour_id}/forms/${form_id}`, authHeader);
        //console.log('bruh')
        console.log(resp);
        let tourData = resp.data.data
        console.log(tourData);
        //tourData.formFields.data = JSON.parse( tourData.formFields.data);
        return tourData
    }

    const submitFormAnswers = async (tour_id:number, form_id:number, payload:any):Promise<any> => {
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}
        console.log(payload)
        let profileData = await makeHttpPost(`/tours/${tour_id}/forms/${form_id}`, payload, authHeader);
        let user = profileData.data.data;
        
        return user
    }

    const getSubmissionHistory = async () => {
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let resp = await makeHttpGet(`/users/self/history`, authHeader);
        let submissionData = resp.data.data
        console.log(submissionData);
        //tourData.formFields.data = JSON.parse( tourData.formFields.data);
        return submissionData
    }

    const getResults = async (tour_form_id:number) => {
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let resp = await makeHttpGet(`/users/self/results/${tour_form_id}`, authHeader);
        let submissionData = resp.data.data
        console.log(submissionData);
        //tourData.formFields.data = JSON.parse( tourData.formFields.data);
        return submissionData
    }
    const getResultsPDF = async (tour_form_id:number) => {
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let resp = await makeHttpGet(`/users/self/results/${tour_form_id}/pdf`, authHeader);
        let submissionData = resp.data.data
        console.log(submissionData);
        //tourData.formFields.data = JSON.parse( tourData.formFields.data);
        return submissionData
    }


    const backUpToStorage = async (form:any):Promise<void> => {
        //await Storage.configure({group:'formBackUps'});
        console.log(form.id);
        
        await Storage.set({
            key: ''+form.id, 
            value:JSON.stringify(form)
        });
    }

    const retriveBackUp = (form_id:string):Promise<GetResult> => {
        //await Storage.configure({group:'formBackUps'});
        return Storage.get({ key: ''+form_id, });
    }

    return {
        reqTourForm,
        submitFormAnswers,
        getSubmissionHistory,
        getResults,
        getResultsPDF,
        backUpToStorage,
        retriveBackUp,
    };
    

}