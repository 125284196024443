export interface DarkModeState {
    dmState: boolean;
}

export function useDarkMode() {

    const toggleDarkTheme = (status: boolean) => {
        document.body.classList.toggle('dark', status);
        return status;
    }
    const getDarkState = ():boolean => {
        return document.body.classList.contains('dark');
    }

    return {
        toggleDarkTheme,
        getDarkState
    };
    

}