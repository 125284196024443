
import { IonButton, IonIcon, IonModal, IonSelect, IonSelectOption } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';
import React, { useState } from 'react';

import './StagePlotModal.css';


import '../../theme/fontawesome.css';

interface StagePlotModalProps {
    stages:any;
    tourName:string;
    stageIndex:any;
    selectedStagePlot:any;
    plotIndex:any;
    handleCloseModal:any;
    handleModalConfim:any;
}



const StagePlotModal:React.FC<StagePlotModalProps> = ({tourName, stages, stageIndex, plotIndex, handleCloseModal, handleModalConfim})=>{
    //const [showModal, setShowModal] = useState(show.show);
    const [selectedStageIndex, setSelectedStageIndex] = useState<number>(stageIndex);
    const [selectedPlotIndex, setSelectedPlotIndex] = useState<number>(plotIndex);
    //console.log(currentQuestionPlots);


    return (
        <div className="sg-stage-modal sg-flex sg-flex-col sg-w-full sg-h-full sg-py-12">
            <div className="sg-flex sg-mx-auto sg-w-9/12 sg-max-w-md sg-mb-8">
                <IonIcon onClick={() => handleCloseModal()} icon={chevronBack} color="secondary" className="sg-text-4xl sg-h-full sg-cursor-pointer"></IonIcon>
                <h1 className="sg-my-0 sg-text-center sg-text-2xl sg-text-gray-700">Cambiar Stage y Plot</h1>
            </div>
            <div className="sg-mx-auto sg-w-9/12 sg-max-w-md sg-space-y-8 sg-flex sg-flex-col">
                <div className="sg-space-y-3">
                    <IonSelect value={tourName} okText="Confirmar" cancelText="Cerrar" disabled={true}>
                        <IonSelectOption value={tourName}>{tourName}</IonSelectOption>
                    </IonSelect>
                    <IonSelect value={selectedStageIndex} okText="Confirmar" cancelText="Cerrar" onIonChange={e => { setSelectedStageIndex(e.detail.value); }}>
                        {stages.map( (stage:any, index:number) => <IonSelectOption value={index} key={index}>{stage.name}</IonSelectOption> )}
                    </IonSelect>
                    <IonSelect value={selectedPlotIndex} okText="Confirmar" cancelText="Cerrar" onIonChange={e => setSelectedPlotIndex(e.detail.value)} className="sg-border sg-border-sgPrimary">
                        {stages[selectedStageIndex] && stages[selectedStageIndex].plots.map( (stage:any, index:number) => <IonSelectOption value={index} key={index}>{stage.name}</IonSelectOption> )}
                    </IonSelect>
                </div>
                <div className="sg-space-y-2 sg-flex sg-flex-col sg-px-8">
                    <IonButton color="secondary" fill="clear" onClick={() => handleModalConfim(selectedStageIndex, selectedPlotIndex)} className="sg-uppercase sg-rounded-full sg-border-2 sg-border-solid sg-border-sgPrimary">Sobrescribir</IonButton>
                    <IonButton onClick={() => handleModalConfim(selectedStageIndex, selectedPlotIndex)} className="sg-uppercase sg-rounded-full">Aceptar</IonButton>
                </div>
            </div>
        </div>
    );
};
export default StagePlotModal;
