import { FC } from "react";
import { IonCheckbox, IonLabel} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";

//type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';

export interface SgFormFieldProps {
    field:any;
    checkBox:any;
    props:any;
    formIndex:number;
    pageIndex:number;
    componentIndex:number;
    optionIndex:number;
    stageIndex:number;
    plotIndex:number;
    is_satisfaction:any;

}

let lastPlotI = 0;
let lastStageI = 0;

const SgInputCheckBox: FC<any> = ({
    field,
    checkBox,
    formIndex,
    pageIndex,
    componentIndex,
    optionIndex,
    stageIndex,
    plotIndex,
    is_satisfaction,
    ...props
    }) => {

        let fieldValue:any = useSelector((state:any) => {
            try{
                //console.log(`[${componentIndex}][${stageIndex}][${plotIndex}]`);
                //console.log({name:'SgRadioGroup',plotIndex:plotIndex});
                //console.log(state.forms[formIndex].formFields.data[pageIndex].components[componentIndex].values[optionIndex].userInput.stages[stageIndex][plotIndex]);
                return state.forms[formIndex].formFields.data[pageIndex].components[componentIndex].values[optionIndex].userInput.stages[stageIndex][plotIndex];
            }
            catch(e){
                //console.log(e);
                return 0;
            }
        });

        lastStageI = stageIndex;
        lastPlotI = plotIndex;

        const dispatch = useDispatch()
        const updateCheckBox = (value:any):any => {
            if(stageIndex === lastStageI && plotIndex === lastPlotI){
                if(value !== undefined){
                    dispatch({ type: 'UPDATE_FIELD_WITH_CHECK_BOX', payload:{value:value, field:field.tema}, formId:formIndex, pageId:pageIndex, componentId:componentIndex, optionIndex:optionIndex, stageIndex:stageIndex, plotIndex:plotIndex  });
                }
            }
        };
        return(
            <div className="sg-w-full sg-max-w-md sg-mx-auto sg-shadow-xl sg-rounded-xl sg-bg-white sg-px-4 sg-py-4 sg-flex sg-items-center" key={checkBox.value}>
                <IonCheckbox slot="start" color="primary" name={`${stageIndex}_${plotIndex}_${field.key}`} className="sg-rounded-lg" value={checkBox.value} onIonChange={(event) => updateCheckBox(event.detail.checked)} checked={fieldValue}/>
                <IonLabel className="sg-pl-2">{checkBox.label}</IonLabel>
                {/* <pre> {JSON.stringify(fieldValue, null, 4)}</pre> */}
            </div>
        );
};

export default SgInputCheckBox;
