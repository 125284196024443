//import { useHttpReq } from './useHttpReq';
//import { useSelector, useDispatch } from 'react-redux';
//import { Conntection } from '../redux/reducers/DeviceStatusReducer';
import { QueueItem } from '../redux/reducers/SubmisionQueueReducer';
import { useForms } from './useForms';
import { Storage } from '@capacitor/storage';


let isRunningQueue = false;
let storedKeys:string[] = [];
let completedKeys:string[] = [];

export function useSubmisionQueue() {
    isRunningQueue = false;
    //const { makeHttpGet, makeHttpPost, makeHttpPut } = useHttpReq();
    const { submitFormAnswers } = useForms();
    //const dispatch = useDispatch();

    //const connection:Conntection = useSelector((state:any) => state.deviceStatus.connection);
    //const submisionQueue:Array<QueueItem> = useSelector((state:any) => { return state.submisionQueue});


    const addToQueue = async (queueItem:QueueItem):Promise<void> => {
        //await Storage.configure({group:'submisionQueue'});
        await Storage.set({
            key: queueItem.data.queuedAt, 
            value:JSON.stringify({
                tour_id:queueItem.data.tour_id, 
                form_id:queueItem.data.form_id, 
                form_data:queueItem.data.form_data
            })
        });

        let storedKeysJSON = await Storage.get({key: 'queueKeys'});
        if(storedKeysJSON.value){
            storedKeys = JSON.parse(storedKeysJSON.value);
        }
        storedKeys.push(queueItem.data.queuedAt);
        //console.log(storedKeys);
        await Storage.set({ key: 'queueKeys', value:JSON.stringify(storedKeys) });
    }

    const processQueue = async ():Promise<any> => {
        if(!isRunningQueue){
            isRunningQueue = true;
            //await Storage.configure({group:'submisionQueue'});

            let storedKeysJSON = await Storage.get({key: 'queueKeys'});
            if(storedKeysJSON.value){
                storedKeys = JSON.parse(storedKeysJSON.value);
            }

            let completedKeysJSON = await Storage.get({key: 'completedKeys'});
            if(completedKeysJSON.value){
                completedKeys = JSON.parse(completedKeysJSON.value);
            }

            storedKeys = storedKeys.filter((sKey:string) => !completedKeys.includes(sKey))


            //console.log(storedKeys);
            if(storedKeys.length){
                console.log('starting queue'); 

                storedKeys.forEach(async (submision:any, index:number, queueKeys:string[]) => {

                    let existingItemJSON = await Storage.get({key: submision});
                    uploadQueueItem( existingItemJSON ).then(()=> {
                        if(index >= queueKeys.length-1){
                            queueComplete();
                        }
                    }).catch(()=>{
                        if(index >= queueKeys.length-1){
                            queueComplete();
                        }
                    });
                    await Storage.remove({key: submision});
                    completedKeys.push(submision);
                    await Storage.set({ key: 'completedKeys', value:JSON.stringify(completedKeys) })
                    //await Storage.set({ key: 'queueKeys', value:JSON.stringify(storedKeys.splice(index, 1)) })
                });
    
                //dispatch({type:'CLEAR_QUEUE'});
            }else{
                isRunningQueue = true;
                console.log('queue empty');
            }
        }else{
            console.log('queue already runnning');
        }
    }

    const uploadQueueItem = (itemJson:any):Promise<void> =>{
        return new Promise((resolve, reject) => {
            if(!itemJson.value){
                reject();
            }
            let item = JSON.parse(itemJson.value);
            if(item.tour_id && item.form_id && item.form_data){
                submitFormAnswers(item.tour_id, item.form_id, item.form_data)
                .then(() => resolve())
                .catch( () => reject() );
            }else{
                reject();
            }
        });
    }

    const queueComplete = async ():Promise<void> => {
        console.log('queue complete');

        await Storage.set({ key: 'queueKeys', value:JSON.stringify([]) });
        await Storage.set({ key: 'completedKeys', value:JSON.stringify([]) });
        //await Storage.clear();
        //await Storage.configure({});

        isRunningQueue = false;
    }

    return {
        processQueue,
        addToQueue,
    };
    

}