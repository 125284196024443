import React,{ useCallback, useContext, useEffect, useState, } from 'react';
import './Login.css';

import { useAuth, AuthState } from '../../hooks/useAuth';
import { IonContent, IonPage, IonText, NavContext } from '@ionic/react';

const Logout: React.FC = () => {
    const { reqLogout } = useAuth();
    const {navigate} = useContext(NavContext);
    const [fresh, setFresh] = useState(true);
    const toLoginScreen = useCallback(
        () => navigate('/auth/login', 'none'),
        [navigate]
    );
        useEffect(()=>{
            if(fresh){
                reqLogout().then(()=>{
                    setFresh(false);
                    //Using the useContext hook like this throws a warning, im ignoring this :^)
                    toLoginScreen(); 
                });
            }
        }, [fresh, setFresh, toLoginScreen, reqLogout]);
    return (
        <IonPage className="auth-page">
            <IonContent fullscreen>
                <div style={{paddingTop:'clamp(16vw, 300px, 20vh)'}}>
                    <IonText>
                        <h1 style={{textAlign:'center'}}>Logging Out</h1>
                    </IonText>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Logout;
