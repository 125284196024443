import React,{ useEffect, useState, } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonMenuButton, IonRefresher, IonRefresherContent, IonImg, IonButtons, IonBackButton} from '@ionic/react';
//import './Profile.css';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import { chevronDownCircleOutline } from 'ionicons/icons';
import ContactCard from '../../components/contacts/ContactCard';
import { useContact } from '../../hooks/useContact';
import { useDispatch, useSelector } from 'react-redux';


const Contacto: React.FC = () => {
    const {reqUserContactCards} = useContact();
    //const { addFromDevice } = usePhotoGallery();
    /* const [contacts, setContacts] = useState<any>(
        [
            {
                name:'',
                roll: '',
                phone:'', 
                email:'',
                photo_path:'', 
                country:'',
                description:''
            },
    ]); */
    let dispatch = useDispatch();
    let contacts = useSelector((state:any)=> state.contactCards);
    const [fresh, setFresh] = useState(true);
    const [loading, setLoading] = useState(false);

    const doRefresh = (event:any=null) => {
        reqUserContactCards().then((cardsData:any)=> {
            console.log(cardsData);
            dispatch({type:'SAVE_CARDS', payload:{contactCards:cardsData}})
            //setContacts(cardsData);
            if(event){
                event.detail.complete();
            }
        })
        .catch((error)=> {
            //setValidations(error);
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }


    useEffect(()=>{
        if(fresh){
            setLoading(true);
            if(!contacts.length){
                console.log('flag');
                doRefresh();
            }
            setLoading(false);
            setFresh(false);
        }
    }, [setLoading, setFresh, fresh, contacts, dispatch, reqUserContactCards, ]);

    
    
    /* let contacts = Array.apply(null, Array(8)).map(function (x, i) { 
        return {
            name:'James Alberts',
            title: 'Survey Director',
            tel:'(303) 555-0105', 
            email:'jame.alberts@example.com',
            photo_path:process.env.REACT_APP_BASE_URL + '/img/YrRqcygTfho.jpg', 
            font:0,
            country:'México',
            description:'Enim labore Lorem occaecat anim mollit voluptate.'
        }; 
    }); */

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    {/* <IonTitle size="large">Profile</IonTitle> */}
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Contacto</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                    <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Tire para refrescar"
                    refreshingSpinner="circles"
                    refreshingText="Refrescando...">
                    </IonRefresherContent>
                </IonRefresher>
                <div className="sg-text-center sg-flex sg-flex-col sg-w-full sg-items-center sg-px-4 sg-py-4 sg-space-y-6">
                    {contacts && contacts.map((contact:any) => <ContactCard contact={contact}/>)}
                    {(contacts && contacts.length <= 0) && 
                        <div className="sg-flex sg-justify-center sg-items-center sg-flex-col sg-pt-24">
                            <h3 className="sg-text-gray-700">No hay contactos disponibles.</h3>
                            <IonImg src={'/assets/images/syngenta_logo_black.png'} className="sg-h-14 sg-w-60 sg-opacity-50"/>
                        </div>
                    }
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Contacto;
