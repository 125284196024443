import { IonIcon, IonProgressBar, IonToast, IonAlert,  useIonModal, useIonToast } from '@ionic/react';
import { close, download, refresh,} from 'ionicons/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import { useForms } from '../../hooks/useForms';
import './FormListItem.css';
import RefreshConfirmModal from './RefreshConfirmModal';
interface ContainerProps {
    tourId:number;
    form:any;
    showIcon:boolean;
    formIds:Array<number>;
    isConnected:boolean;
    date:any;
}

const FormListItem: React.FC<ContainerProps> = ({ tourId, form, showIcon, formIds, isConnected, date}) => {
    const user = useSelector((state:any) => { return state.user});
    const fecha_hoy= new Date();
    const date_1= new Date(date).getTime();
    const { reqTourForm } = useForms();

    const [downloading , setDownloading] = useState(false);
    const [alreadyDownloaded , setAlreadyDownloaded] = useState(false);
    const dispatch = useDispatch();

    const [showToast, setShowToast] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const downloadTour = () => {
        setDownloading(true);
        return reqTourForm(tourId, form.id).then((resp:any) => {
            if(!resp.formFields){
                toastPresent({cssClass:'sg-special-toast sg--top-8 sg-text-base sg-text-center', duration:2000, position:'top', header:'', message: '<strong>Error:</strong> Este Formulario no tiene preguntas',});
            }else{
                if(resp.formFields.data.length){
                    resp.formFields.data = JSON.parse(resp.formFields.data)
                    resp.formFields.data.push({insert_last_slide:true})
                }
                dispatch({ type: 'SAVE_FORM_FIELDS', payload:{form:resp}, formId:form.id });
                //setForm(resp);
                //setDisableDl(true);
                setAlreadyDownloaded(true);
            }
            setDownloading(false);
        }).catch((error:any) => {
            console.log(error);
            setDownloading(false);
        });
    }

    const checkIfDownloaded = useCallback(()=>{
        return ( undefined !== formIds.find(id=> form.id===id) );
    }, [form.id, formIds]);

    const refreshModal = useCallback(()=>{
        return ( undefined !== formIds.find(id=> form.id===id) );
    }, [form.id, formIds]);

    const handleModalAccept = () => {
        downloadTour().then(()=> dismiss());
    };
    const handleModalDismiss = () => {
        dismiss();
    };


    const [present, dismiss] = useIonModal(RefreshConfirmModal, { handleModalAccept:handleModalAccept, handleModalDismiss:handleModalDismiss });
    const [toastPresent, toastDismiss] = useIonToast();

    const customModalPresent = () =>{ // you could techinicaly move this present call directly into the onclick declaration but it looks gross there
        present({
            cssClass:'sg-special-modal',
            showBackdrop:true,
            backdropDismiss:true,
        });
    }

    useEffect(()=>{
        setAlreadyDownloaded(checkIfDownloaded());
    }, [checkIfDownloaded, setAlreadyDownloaded]);

    return (
            <div className={"sg-w-full sg-text-left sg-px-4 pt-1 sg-relative" + (!isConnected && !alreadyDownloaded ? ' sg-bg-gray-100' : '')}>
                <div className="row">

                { fecha_hoy.getTime() > date_1 || user.roles[0].name !== "user" ?
                    <Link to={!isConnected && !alreadyDownloaded ? '' : `/tours/${tourId}/forms/${form.id}`} className={showIcon ? "sg-no-underline sg-w-8/12" : "sg-no-underline sg-w-full"}>
                        <div>
                            <h1 className="sg-text-sgBlue sg-font-bold">{form.title}</h1>
                        </div>
                        <div>

                            <p className="sg-text-gray-500 description_text">{form.is_satisfaction}</p>

                        </div>
                    </Link>
                  :

                  <Link to={`/tours/${tourId}/forms/`} className={showIcon ? "sg-no-underline sg-w-8/12" : "sg-no-underline sg-w-full"} onClick={() =>setShowAlert(true)}>
                      <div>
                          <h1 className="sg-text-sgBlue sg-font-bold">{form.title}</h1>
                      </div>
                      <div>
                          <p className="sg-text-gray-500 description_text">{form.description}</p>
                      </div>
                  </Link>

                }
                    {showIcon ?
                    <div className="sg-text-gray-500 sg-flex sg-items-center sg-w-4/12">
                        <div className="sg-text-center sg-w-30 sg-h-16 sg-mx-auto sg-flex sg-flex-col sg-items-center sg-justify-between" onClick={() => {if(isConnected){alreadyDownloaded ?  customModalPresent() : downloadTour()}}}>
                            <IonIcon className={"sg-h-6 sg-w-6 sg-mt-2 " + ((alreadyDownloaded && downloading) ? 'sg-animate-spin' : '') + (isConnected ? ' sg-text-sgSecondary sg-text-opacity-100' : ' sg-text-gray-300')} style={{transformOrigin:'50% 58%'}} icon={ alreadyDownloaded ? refresh : download}></IonIcon>
                            <div className="sg-w-full">
                                { !alreadyDownloaded && <p className="sg-text-gray-800 sg-my-0 sg-text-sm">Descargar</p>}
                                { alreadyDownloaded &&
                                    <p className="sg-text-gray-600 sg-my-0 sg-text-sm">
                                        <span>Versión: </span>
                                        <span className="sg-font-semibold">{new Date(form.updated_at).toLocaleDateString()}</span>
                                    </p>
                                }
                                {downloading && <IonProgressBar type="indeterminate"></IonProgressBar>}
                            </div>
                        </div>
                    </div> : <div></div>}
                </div>
                {(!isConnected && !alreadyDownloaded) &&
                    <div className="sg-absolute sg-bg-gray-300 sg-bg-opacity-25 sg-w-full sg-h-full sg-z-50 sg-top-0 sg-left-0" onClick={() =>
                        setShowToast(true)
                    }></div>
                }
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    cssClass="sg-font-normal sg-text-base sg-text-center" //text-center doesnt quite align the message to the center of the toast becuase of the close button.
                    //the message needs left padding
                    message=" Este formulario no está disponible." //this is a really dumb way of adding left padding to the message but ionic has forced my hand
                    duration={3000}
                    color="dark"
                    buttons={[
                    {
                        icon:close,
                        role: 'cancel',
                        handler: () => {
                        //console.log('Cancel clicked');
                        }
                    }
                    ]}
                />
                <IonAlert
                  isOpen={showAlert}
                  onDidDismiss={() => setShowAlert(false)}
                  header="Alerta"
                  message="Este formularo no se encuentra disponible para la fecha."
                  buttons={['OK']}
                />
            </div>
    );
};

export default FormListItem;
