import { IonBackButton, IonButtons, IonCard, IonContent, IonHeader, IonImg, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, IonCardHeader, IonCardContent, IonItem, IonIcon } from '@ionic/react';
import { chevronDownCircleOutline, informationCircle, clipboard, playCircleOutline, playCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FullscreenLoader from '../../components/FullscreenLoader';
import TourCard from '../../components/tours/TourCard';
import { DEFUALT_TOUR_IMG_PATH } from '../../helpers/ImagePaths';
import { useForms } from '../../hooks/useForms';
import './Historical.css';

const Historical:React.FC = () => {
    let { getSubmissionHistory, } = useForms();

    const [fresh, setFresh] = useState(true);
    const dispatch =  useDispatch();

    let histroy = useSelector((state:any) => {
        console.log(state.history);
        return state.history;
    })


    let tourFormsAnswered:Array<string> = [];
    const [filteredHistroy , setFilteredHistroy] = useState<Array<any>>([]);

    const doRefresh = (event:any=null) => {
        getSubmissionHistory().then((resp:any) => {
            console.log(resp);
            dispatch({ type: 'SAVE_HISTORY', payload:{history:resp} });
            if(event){
                event.detail.complete();
            }
        }).catch((error:any) => {
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }

    useEffect(()=>{
        if(fresh){
            if(!histroy){
                doRefresh();
                setFresh(false);
            }else{
                tourFormsAnswered=[];
                setFilteredHistroy(histroy.filter((entry:any) => {
                    let doesContain = tourFormsAnswered.includes(entry.tour_form_id); 
                    if(!doesContain){
                        tourFormsAnswered.push(entry.tour_form_id);
                    } 
                    console.log({doesContain:!doesContain,tourFormsAnswered:tourFormsAnswered}); 
                    return !doesContain;
                }));
            }
        }
    }, [setFresh, fresh]);
    
    return (
        <IonPage>
            { histroy && 
            <div>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/"text="Atrás"/>
                        </IonButtons>
                        <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                        {/* <IonTitle>Historical</IonTitle> */}
                        <IonMenuButton slot="end"></IonMenuButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Historial de Resultados</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                        <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Tire para refrescar"
                        refreshingSpinner="circles"
                        refreshingText="Refrescando...">
                        </IonRefresherContent>
                    </IonRefresher>
                    <div>
                        {
                            filteredHistroy.map((entry: any) => {
                                return <Link className="sg-overflow-visible sg-block sg-no-underline" to={"/results/" + entry.tour_form_id}>
                                <IonCard className="sg-overflow-visible sg-h-32">
                                    <div className="sg-flex">
                                        <img className="sg-object-cover sg-rounded-lg sg-w-28 sg-h-28 sg-my-2 sg-ml-2 sg-mr-1" src={ process.env.REACT_APP_BASE_URL + '/' + (entry.tourForm.tour.feature_image || DEFUALT_TOUR_IMG_PATH )} alt="asd" />
                                        <div className="sg-flex-direction-column sg-flex-3 sg-my-2 sg-mr-2">
                                            <strong className="sg-text-sgBlue sg-text-xl sg-no-underline sg-pt-5 sg-px-3">{entry.tourForm.form.title}</strong>
                                            <p className="sg-text-sgSecondary sg-text-lg sg-no-underline sg-my-0 limit_text sg-pt-1 sg-px-3 sg-mb-0 sg-leading-snug">{entry.tourForm.tour.name}</p>
                                        </div>
                                    </div>
                                </IonCard>
                                </Link>
                            })
                        }
                        {(filteredHistroy && filteredHistroy.length <= 0) && 
                            <div className="sg-flex sg-justify-center sg-items-center sg-flex-col sg-pt-24">
                                <h3 className="sg-text-gray-700">No hay historial.</h3>
                                <IonImg src={'/assets/images/syngenta_logo_black.png'} className="sg-h-14 sg-w-60 sg-opacity-50"/>
                            </div>
                        }
                    </div>
                </IonContent>
            </div>

            }
            { !histroy && <FullscreenLoader/> }
        </IonPage>
    )
}

export default Historical;