const initialState:any = []

export const SAVE_RESULTS = 'SAVE_RESULTS'
export const SAVE_ALL_RESULTS = 'SAVE_ALL_RESULTS'
export const SAVE_FORM_RESULTS = 'SAVE_FORM_RESULTS'
export interface AllResults{
    results: Array<Results>;
};
export interface Results{
    results:any;
};
export interface SaveAllResultsActions{
    type: typeof SAVE_ALL_RESULTS
    payload: AllResults
};
export interface SaveFormResultsActions{
    type: typeof SAVE_FORM_RESULTS
    payload: Results
};


  // Use the initialState as a default value
export default function resultsReducer(state:any = initialState, action:SaveFormResultsActions|SaveAllResultsActions) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case 'SAVE_ALL_RESULTS':
            console.log(action);
            return action.payload.results;

        // Do something here based on the different types of actions
        case 'SAVE_FORM_RESULTS':
            console.log(state);
            let results = state ? state : [];
            let foundFormA:boolean = false;
            let foundFormIndexA:number = 0;
            for (const result of results) {
                if(result.id == action.payload.results.id){
                    foundFormA=true;
                    results[foundFormIndexA] = action.payload.results;
                    break;
                }
                foundFormIndexA++;
            }
            results = foundFormA ? results: [...results, action.payload.results];
            return results;
        // Do something here based on the different types of actions
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}