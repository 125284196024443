import { createStore } from 'redux'
import rootReducer from '../reducers/RootReducer';
import { Storage } from '@ionic/storage';

const storage = new Storage();
storage.create();



const CreateNewBackedUpStore = () => {
    return new Promise((resolve:any, reject)=>{
        storage.get('appState').then((storedState)=>{
            const persistedState = storedState ? storedState : {}
            
            const Store = createStore(rootReducer, persistedState);
            
            Store.subscribe(()=>{
                storage.set( 'appState', Store.getState() )
                .then((file)=> {
                    //console.log( Store.getState())
                })
                .catch((e:any) => {
                    console.log(e);
                });
            })
            resolve(Store);
        })
        .catch((e)=> {
            console.log(e);
            reject({});
        });

    })



}

export default CreateNewBackedUpStore;