


import React,{ useCallback, useContext, useEffect, useState, } from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonLoading, IonMenuButton, IonPage, IonSpinner, IonTitle, IonToolbar, NavContext, IonImg } from '@ionic/react';
import { useTours } from '../../hooks/useTours';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FullscreenLoader from '../../components/FullscreenLoader';
import { ProfileObject, useProfile } from '../../hooks/useProfile';
import { create, createOutline } from 'ionicons/icons';
import { Conntection } from '../../redux/reducers/DeviceStatusReducer';
import { DEFUALT_PFP_PATH } from '../../helpers/ImagePaths';

const AddTour: React.FC = () => {
    let { id }:any = useParams();
    /* const { reqTour } = useTours();
    reqTour(id); */
    const { reqSelfProfile, reqMyTours } = useProfile();
    const { reqActivateInvite } = useTours();

    const [fresh, setFresh] = useState(true);
    const [inviteCode, setInviteCode] = useState('');
    const [isFlashDisplaying, setIsFlashDisplaying]:any = useState(false);
    const [errorText, setErrorText]:any = useState('Can not activate invite code.');
    const [isLoading, setIsLoading]:any = useState(false);
    const [success, setSuccess]:any = useState(false);
    

    const connection:Conntection = useSelector((state:any) => state.deviceStatus.connection);
    
    const dispatch = useDispatch();

    const {navigate} = useContext(NavContext);

    const [user, setUser] = useState<ProfileObject>({
        username:'',
        pfp_path:'',
        name:'',
        country:'',
        phone:'',
        roles:[],
    });
    const [tours, setTours]:any = useState([]);

    useEffect(()=>{
        if(fresh){
            reqSelfProfile().then(resp => {
                setUser(resp);
            }).catch(error => console.log(error));
            setFresh(false);
        }
    }, [setFresh, fresh, reqSelfProfile, reqMyTours, tours, dispatch]);

    const tryActivateInvite = () => {
        setIsLoading(true);
        reqActivateInvite(inviteCode).then((resp) => {
            setInviteCode('')
            setIsLoading(false);
            dispatch({ type: 'ADD_TOUR', payload:{tour:resp} });
            setSuccess(true);
            //Using the useContext hook like this throws a warning, im ignoring this :^)
            setTimeout(
                () => {
                    setSuccess(false)
                    setInviteCode('')
                    setIsLoading(false);
                    toHomeScreen()
                },
                1000
            );
        
        }).catch((error) => {
            setIsLoading(false);
            if(error.response && error.response.data && error.response.data.data && error.response.data.data.errorCode){
                setErrorText(error.response.data.data.message);
                if(error.response.data.data.errorCode==='DUPLICATE_USER_TOUR'){
                    setErrorText(`${error.response.data.data.message}: \n ${error.response.data.data.data.tour.name}`);
                }
            }
            flashError();
        });
    }

    const toHomeScreen = useCallback(
        () => navigate('/', 'none'),
        [navigate]
    );

    const flashError = ():any => {
        setIsFlashDisplaying(true)
        setTimeout(
            () =>setIsFlashDisplaying(false), 
            3000
        );
    }

    return (
        <IonPage>
            { isFlashDisplaying && 
            <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-red-300 sg-border-red-800 sg-border-4 sg-border-solid sg-text-red-800 sg-z-50 sg-rounded-b-2xl">
                <span><strong>Error:</strong> {errorText}</span>
            </div>
            }
            { success && 
            <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-green-300 sg-border-gree-800 sg-border-4 sg-border-solid sg-text-green-800 sg-z-50 sg-rounded-b-2xl">
                <span><strong>Realizado con éxito</strong></span>
            </div>
            }
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <span className="sg-bg-grad-vertical sg-py-4 sg-w-full sg-text-center sg-font-bold" style={{display:'block', color:'white'}}>Code</span>
                <div className="sg-px-4 sg-w-full sg-flex sg-flex-col sg-justify-center sg-items-center sg-py-4 sg-text-center">
                        <div className="sg-profile-image sg-h-20 sg-w-20">
                            <img className="sg-profile-image-display sg-h-20 sg-w-20 sg-text-center sg-rounded-full" alt="" src={user.pfp_path ? process.env.REACT_APP_BASE_URL + '/' + user.pfp_path :  DEFUALT_PFP_PATH}/>
                            {/* <div className="sg-profile-image-upload sg-text-center sg-rounded-full sg-shadow-lg sg-bg-gray sg-text-gray-500 sg-bg-gray-300 sg-h-9 sg-w-9 sg--bottom-0 sg--right-2">
                                <IonIcon className="sg-h-6 sg-w-6 sg-pl-1 sg-absolute sg-top-1/2 sg-left-1/2 sg-transform sg--translate-x-1/2 sg--translate-y-1/2" ios={createOutline} md={create}/>
                            </div> */}
                        </div>
                        <div className="sg-h-20 sg-flex sg-flex-col sg-justify-center sg-text-gray-600 sg-leading-normal">
                            <span className="sg-font-semibold">{user.name}</span>
                            <span className="sg-font-light">PERFIL</span>
                        </div>
                    <IonInput 
                    placeholder="Ingresa tu código de invitación" 
                    className=" login-input sg-px-2 sg-w-full sg-h-12 sg-appearance-none sg-border sg-border-solid sg-rounded-xl sg-border-sgBlue sg-text-left" 
                    type="text"
                    onIonChange={e => setInviteCode(e.detail.value!)}
                    value={inviteCode}
                    />
                    <IonButton className="sg-rounded-full sg-mt-24 sg-bg-gradient-to-r sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-mb-4 sg-w-11/12 sg-max-w-xs sg-h-12 sg-text-lg" onClick={tryActivateInvite} disabled={isLoading || !connection.connected}>ACEPTAR</IonButton>
                    { isLoading &&
                        <IonSpinner />
                    }
                    {/* { success && 
                        <IonSpinner  name="dots" color="primary"/>
                    } */}
                </div>
                
            </IonContent>
        </IonPage>
    );
};

export default AddTour;
