import { IonCard, IonCardContent, IonCardHeader, IonIcon } from '@ionic/react';
import { clipboard, informationCircle, playCircle, playCircleOutline } from 'ionicons/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { DEFUALT_TOUR_IMG_PATH } from '../../helpers/ImagePaths';
import './TourCard.css';
interface ContainerProps {
    tour:any;
}

const TourCard: React.FC<ContainerProps> = ({ tour }) => {
    return (
        <Link className="tour-card sg-overflow-visible sg-block sg-w-full sg-no-underline" to={"/tours/"+tour.id}>
            <IonCard className="sg-overflow-visible">
                <img className="tour-card-img sg-rounded-t-lg" src={ tour.feature_image ? process.env.REACT_APP_BASE_URL + '/' +tour.feature_image : DEFUALT_TOUR_IMG_PATH } alt={tour.name + ' image'}/>
                <IonCardHeader className="sg-text-center">
                    <p className="sg-text-sgSecondary sg-text-lg sg-no-underline sg-my-0">Gira de campo</p>
                    <strong className="sg-text-sgBlue sg-text-xl sg-no-underline">{tour.name}</strong>
                </IonCardHeader>
                <IonCardContent>
                </IonCardContent>
                <div className="sg-relative sg-h-12">
                    <div className="sg-absolute sg-overflow-visible sg-bottom--1/3 sg-z-40 sg-flex sg-justify-around sg-w-full sg-h-28 sg-text-white sg-text-center">
                        <div className="sg-h-full sg-w-28 sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-rounded-xl sg-p-2 sg-py-4 sg-shadow">
                            <IonIcon className="sg-text-white sg-text-4xl" ios={informationCircle} md={informationCircle}/>
                            <p>Información General</p>
                        </div>
                        <Link className="sg-no-underline sg-text-white" to={"/tours/"+tour.id+"/details"}>
                            <div className="sg-h-full sg-w-28 sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-rounded-xl sg-p-2 sg-py-4 sg-shadow">
                                <IonIcon className="sg-text-white sg-text-4xl" ios={clipboard} md={clipboard}/>
                                <p>Información de la Gira</p>
                            </div>
                        </Link>
                        <Link className="sg-no-underline sg-text-white" to={"/tours/"+tour.id+"/forms/"}>
                            <div className="sg-h-full sg-w-28 sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-rounded-xl sg-px-2 sg-py-4 sg-shadow">
                                <IonIcon className="sg-text-white sg-text-4xl" ios={playCircleOutline} md={playCircle}/>
                                <p>Inicio de Gira</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </IonCard>
        </Link>
    );
};

export default TourCard;
