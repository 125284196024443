import React,{ useCallback, useState, } from 'react';
import {IonContent, IonInput, IonItem, IonLabel, IonPage, IonProgressBar, IonText, IonImg} from '@ionic/react';
import { IonCard, IonCardContent, IonIcon, IonButton } from '@ionic/react';
import './Login.css';

import { useAuth } from '../../hooks/useAuth';
import { grid, logInOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useValidation } from '../../hooks/useValidation';
import { ConnectionStatus, Network } from '@capacitor/network';

const Login: React.FC = () => {
    const { reqLogin } = useAuth();
    const { setValidations } = useValidation();
    const [userName, setUserName] = useState<string>();
    const [pass, setPass] = useState<string>();
    const [loading, setLoading] = useState(false);
    //const history = useHistory();
    const dispatch = useDispatch();
    let loginToken:any;
    const incrementCounter = useCallback(
        () => dispatch({ type: 'SAVE_AUTH', payload:{jwToken:loginToken} }),
        [dispatch, loginToken]
    );
    const [missed, setMissed] = useState(false);
    const tryLogin = async () => {
        try{
            setLoading(true);
            setMissed(false);
            loginToken = await reqLogin(''+userName, ''+pass);
            
        }catch(error){
            setMissed(true);
            setValidations(error);
        }
        //console.log(loginToken);
        incrementCounter();
        setLoading(false);
    }

    Network.getStatus().then((status:ConnectionStatus)=>{
        //console.log(status);
        //setNetworkState(status.connectionType);
        dispatch({ type: 'SAVE_CONECTION_DATA', payload:{...status} });
    });
    
    return (
        <IonPage className="auth-page">
            <IonContent fullscreen>
                <IonImg className="logo_img sg-mx-auto sg-mt-6" src={'/assets/images/data_logo.png'} alt="logo" style={{width:'10em'}}/>
                <div style={{paddingTop:'clamp(4vw, 100px, 12vh)', display:'grid', gridTemplateColumns:'100%', gridTemplateRows:'4rem auto' }} className="sg-w-full sg-max-w-screen-sm sg-mx-auto">
                    <div>
                        { missed ?<span className="sg-rounded-xl sg-p-4 validation-error sg-text-white sg-bg-red-400 sg-w-11/12 sg-max-w-sm sg-block sg-mx-auto">Las credenciales ingresadas son incorrectas.</span> : '' }
                    </div>
                    <div>
                        <IonText>
                            <h1 className="sg-text-sm sg-uppercase sg-tracking-wider" style={{textAlign:'center'}}>Iniciar Sesión</h1>
                        </IonText>
                        <IonCard className="sg-bg-transparent sg-shadow-none">
                            <IonCardContent className="sg-space-y-4">
                                <div>
                                    <IonInput className="login-input sg-px-2 sg-w-full sg-h-12 sg-appearance-none sg-border sg-border-solid sg-rounded-xl sg-border-sgBlue" type="email" inputmode="email" value={userName} placeholder="Ingresa tu email" onIonChange={e => setUserName(e.detail.value!)} autocomplete="username"></IonInput>
                                </div>
                                <div>
                                    <IonInput className="login-input sg-px-2 sg-w-full sg-h-12 sg-appearance-none sg-border sg-border-solid sg-rounded-xl sg-border-sgBlue" type="password" value={pass} placeholder="Ingresa tu contraseña" onIonChange={e => setPass(e.detail.value!)} autocomplete="current-password" clearOnEdit={false}></IonInput>
                                </div>

                                <div style={{textAlign:'center'}}>
                                    <IonText>
                                        <Link to="/auth/reset" className="sg-text-white sg-no-underline sg-text-base">¿Olvidaste tu contraseña?</Link>
                                    </IonText>
                                </div>
                                <div>
                                    <IonButton className="sg-rounded-full sg-h-12 sg-w-64 sg-mx-auto sg-shadow-none sg-text-base sg-tracking-wider sg-font-normal sg-login-btn" expand="block" onClick={async (e) => {await tryLogin()}} disabled={loading}>
                                        <IonText>Iniciar Sesión</IonText>
                                    </IonButton>
                                </div>
                            </IonCardContent>
                            { loading ? <IonProgressBar type="indeterminate" className="login-progress sg-animate-pulse"></IonProgressBar> : null}
                            
                        </IonCard>
                    </div>
                </div>
                <div style={{textAlign:'center'}}>
                    {/* <IonText>
                        Dont have an account? <Link to="/auth/register">Register</Link>
                    </IonText> */}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;
