import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import React from "react";
import { Redirect, Route } from "react-router";
import Menu from "../components/Menu";
import AuthRoot from "./auth/AuthRoot";
import Contacto from "./contact/Contacto";
import Home from "./main/Home";
import Tab2 from "./main/Tab2";
import Tab3 from "./main/Tab3";
//import TabRoot from "./main/TabRoot";
import Notifs from "./notifications/Notifs";
import Historical from "./results/Historical";
import HistoricalFormsList from "./results/HistoricalFormsList";
import Results from "./results/Results";
import Settings1 from "./settings/Settings1";
import AddTour from "./tours/AddTour";
import Tour from "./tours/Tour";
import TourDetials from "./tours/TourDetails";
import TourForm from "./tours/TourForm";
import TourFormsList from "./tours/TourFormsList";
import Profile from "./user/Profile";


const SynSplitPane: React.FC = () => {
    return (
        <IonReactRouter>
            <IonSplitPane contentId="main">
                <IonRouterOutlet id="main">
                    <Route path="/" render={() => <Redirect to="/main" />} exact={true} />
                    <Route path="/(notifs)" component={Notifs} exact={true} />
                    <Route path="/(contacto)" component={Contacto} exact={true} />
                    <Route path="/:section(user)/self" component={Profile} exact={true}/>
                    <Route path="/:section(user)/:id" component={Profile}/>
                    <Route path="/:section(settings)/:name" component={Settings1}/>
                    <Route path="/main" component={Home} exact={true} />
                    <Route path="/main/tab2" component={Tab2} exact={true} />
                    <Route path="/main/tab3" component={Tab3} exact={true} />
                    <Route path="/auth/login" render={() => <Redirect to="/main" />} exact={true}/>
                    {/* <Route path="/auth/register" render={() => <Redirect to="/main" />} exact={true}/> */}
                    <Route path="/auth/reset" render={() => <Redirect to="/main" />} exact={true}/>
                    <Route path="/auth/logout" component={AuthRoot} exact={true}/>
                    <Route path="/:section(tours_add)" component={AddTour} exact={true}/>
                    <Route path="/:section(tours)/:id" component={Tour}/>
                    <Route path="/:section(tours)/:id/details" component={TourDetials} exact={true}/>
                    <Route path="/:section(tours)/:id/forms/" component={TourFormsList} exact={true}/>
                    <Route path="/:section(tours)/:id/forms/:form_id" component={TourForm}/>
                    <Route path="/:section(history)" component={Historical} exact={true}/>
                    <Route path="/:section(history)/:id/" component={HistoricalFormsList} exact={true}/>
                    <Route path="/:section(results)" component={Results} exact={true}/>
                    <Route path="/:section(results)/:tour_form_id" component={Results}/>
                </IonRouterOutlet>
                <Menu />
            </IonSplitPane>
        </IonReactRouter>
    );
}

export default SynSplitPane;
