import { IonCard, IonCardContent, IonIcon } from '@ionic/react';
import { mail, phonePortraitOutline } from 'ionicons/icons';
import React from 'react';
import { DEFUALT_PFP_PATH } from '../../helpers/ImagePaths';
import CallTo from '../misc/CallTo';
import MailTo from '../misc/MailTo';
import './ContactCard.css';
interface ContainerProps {
    contact:any;
}

const ContactCard: React.FC<ContainerProps> = ({ contact }) => {
    return (
            <div className="sg-pt-24 sg-w-full">
                <IonCard className="sg-overflow-visible sg-w-full sg-text-left sg-text-gray-400 sg-m-0">
                    <IonCardContent className="sg-grid sg-grid-cols-2 sg-gap-3 sg-relative">
                        <div  className="sg-col-span-1 sg-row-start-1 sg-row-end-1 sg-relative ">
                            <img className={"sg-contact-image-display sg-col-span-1 sg-row-start-1 sg-row-end-1 sg-rounded-full sg-w-32 sg-h-32 sg-shadow-lg sg-absolute sg--top-20 sg-left-2 " + ( contact ? '': 'sg-animate-pulse sg-bg-gray-200')} src={ process.env.REACT_APP_BASE_URL + '/' + (contact.feature_image || DEFUALT_PFP_PATH)} alt="asd" />
                        </div>
                        <div className="sg-col-start-2 sg-flex sg-items-baseline">
                            <div className="sg-w-full pr-1">
                                <span className={"sg-text-sgBlue sg-text-lg sg-no-underline sg-font-bold sg-min-h-6 sg-min-w-full sg-block " + (contact.name ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{contact.name}</span>
                                <p className={"sg-font-normal sg-leading-6 sg-text-lg sg-no-underline sg-my-0 sg-min-h-6 sg-min-w-full " + (contact ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded sg-mt-1')}>{contact.roll}</p>
                            </div>
                        </div>
                        <div className="sg-col-span-2">
                            <span className="sg-text-sgBlue sg-text-lg sg-no-underline sg-font-bold">Descripción</span>
                            <p className={"sg-text-lg sg-leading-6 sg-min-h-6 sg-min-w-48 " + (contact.description ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{contact.description}</p>
                        </div>
                        <div className="sg-col-span-2 sg-flex sg-space-x-6">
                            <div className="sg-text-3xl sg-flex sg-flex-col sg-justify-center sg-items-center sg-text-center sg-leading-10 sg-w-12 sg-h-12 sg-rounded-full sg-shadow-lg sg-bg-gradient-to-tl sg-from-sgPrimary sg-to-sgSecondary sg-text-white">
                                <IonIcon icon={phonePortraitOutline}/>
                            </div>
                            <CallTo phone={contact.phone}>
                                <span className="sg-text-sgBlue sg-text-lg sg-no-underline sg-font-bold">Teléfono</span>
                                <p className={"sg-text-lg sg-leading-6 sg-min-h-6 sg-min-w-full " + (contact.phone ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{contact.phone}</p>
                            </CallTo>
                        </div>
                        <div className="sg-col-span-2 sg-flex sg-space-x-6">
                            <div className="sg-text-3xl sg-flex sg-flex-col sg-justify-center sg-items-center sg-text-center sg-leading-10 sg-w-12 sg-h-12 sg-rounded-full sg-shadow-lg sg-bg-gradient-to-tl sg-from-sgPrimary sg-to-sgSecondary sg-text-white">
                                <IonIcon icon={mail}/>
                            </div>
                            <MailTo email={'mailto: ' + contact.email}>
                                <span className="sg-text-sgBlue sg-text-lg sg-no-underline sg-font-bold">Email</span>
                                <p className={"sg-text-lg sg-leading-6 sg-min-h-6 sg-min-w-full " + (contact.email ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{contact.email}</p>
                            </MailTo>
                        </div>
                        <div className="sg-col-span-2 sg-from-sgBlue sg-to-sgSecondary sg-bg-gradient-to-r sg-text-center">
                            <span className="sg-text-white sg-text-lg sg-no-underline sg-font-bold">{contact.country}</span>
                        </div>
                    </IonCardContent>
                </IonCard>
            </div>
    );
};

export default ContactCard;
