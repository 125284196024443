import React,{ useEffect, useState, useContext, useCallback} from 'react';
import {IonRefresher, IonRefresherContent, IonSpinner, IonToast, NavContext} from '@ionic/react';
import { IonContent, IonHeader, IonLabel, IonPage, IonToolbar, IonMenuButton, IonText, IonInput, IonButton, IonIcon, IonButtons, IonBackButton, IonImg } from '@ionic/react';
import './Profile.css';
import { useProfile } from '../../hooks/useProfile';
import { usePhotoGallery } from '../../hooks/usePhotoGallery';
import { chevronDownCircleOutline, close, cloudUploadOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Conntection } from '../../redux/reducers/DeviceStatusReducer';
import { DEFUALT_PFP_PATH } from '../../helpers/ImagePaths';


const Profile: React.FC = () => {
    const {navigate} = useContext(NavContext);
    const {reqSelfProfile, updateSelfProfile} = useProfile();
    const { addFromDevice } = usePhotoGallery();

    const user = useSelector((state:any) => { return state.user});
    const connection:Conntection = useSelector((state:any) => state.deviceStatus.connection);
    const dispatch = useDispatch();
    /* const [user, setUser] = useState<ProfileObject>({
        username:'',
        pfp_path:'',
        name:'',
        country:'',
        phone:'',
        roles:[],
    }); */
    const [fresh, setFresh] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const redirect = useCallback(
        () => navigate('/main', 'back'),
        [navigate]
    );


    const [isFlashDisplaying, setIsFlashDisplaying]:any = useState(false);
    const [errorText, setErrorText]:any = useState('No se pudo actualizar la información.');
    const [success, setSuccess]:any = useState(false);

    const [imageThumbLoading, setImageThumbLoading]:any = useState(false);


    const flashError = ():any => {
        setIsFlashDisplaying(true)
        setTimeout(
            () => setIsFlashDisplaying(false),
            3000
        );
    }

    const tryGetProfile = async () => {
        setLoading(true);
        try{
            doRefresh();
            //setUser(thing);

        }catch(error){
            //setValidations(error);
            console.log(error);

        }
        setLoading(false);
    }

    const doRefresh = (event:any = null) => {
        setIsNewPfp(false);
        reqSelfProfile().then(resp => {
            //console.log(resp);
            //setUser(resp);
            dispatch({ type: 'SAVE_USER_INFO', payload:{
                user:{
                    id:resp.id ? resp.id : '' ,
                    name:resp.name ? resp.name : '' ,
                    email:resp.email ? resp.email : '' ,
                    country:resp.country ? resp.country : '' ,
                    phone:resp.phone ? resp.phone : '' ,
                    roles:resp.roles ? resp.roles : [] ,
                    status:resp.status ? resp.status : '' ,
                    pfp_path:resp.pfp_path ? resp.pfp_path : '' ,
                    username:resp.username ? resp.username : '' ,

                }
            } });
            dispatch({ type: 'SAVE_TOTAL_NOTIF_COUNT', payload:{count:resp.__meta__.resNotifications_count} });
            dispatch({type: 'SAVE_CHECKED_NOTIF_COUNT', payload:{count: resp.__meta__.seenNotifications_count}});
            if(event){
                event.detail.complete();
            }
        }).catch(error => {
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }


    const [isNewPfp, setIsNewPfp] = useState(false);

    const openSelectImage = () => {
        addFromDevice().then( (pfp_path) => {

            setIsNewPfp(pfp_path !== 'flag');
            let store_image_string_in_case_the_user_cancels_selecting_an_image = user.pfp_path ? user.pfp_path : 'flag';
            setImageThumbLoading(true);
            //console.log('from openSelectImage');
            //console.log(pfp_path);
            //console.log('end openSelectImage');

            dispatch({ type: 'SAVE_USER_INFO', payload:{
                user:{
                    ...user,
                    pfp_path:pfp_path !== 'flag' ? pfp_path : store_image_string_in_case_the_user_cancels_selecting_an_image,
                }
            } });
            setImageThumbLoading(false);
        }).catch((error:Error)=>{
            console.log(error.message);
            setErrorText(error.message);
            flashError();
        });
    }

    useEffect(()=>{
        if(fresh){
            tryGetProfile();
            setFresh(false);
        }
    }, [tryGetProfile, setFresh, fresh]);

    const tryProfileUpdate = () => {
        //console.log(user);
        setLoading(true);
        updateSelfProfile(user, isNewPfp).then((resp)=>{
            setLoading(false);
            setSuccess(true);
            setTimeout(
                () =>setSuccess(false),
                3000
            );
        }).catch((error:Error) => {
            setLoading(false);
            // console.log(error.name);
            // console.log(error);
            setErrorText('No se pudo actualizar la información.');
            flashError();
        });
    }
    return (
        <IonPage>
            { isFlashDisplaying &&
            <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-red-300 sg-border-red-800 sg-border-4 sg-border-solid sg-text-red-800 sg-z-50 sg-rounded-b-2xl">
                <span><strong>Error:</strong> {errorText}</span>
            </div>
            }
            { success &&
            <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-green-300 sg-border-gree-800 sg-border-4 sg-border-solid sg-text-green-800 sg-z-50 sg-rounded-b-2xl">
                <span><strong>El perfil ha sido actualizado.</strong></span>
            </div>
            }
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    {/* <IonTitle >Profile</IonTitle> */}
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                        <IonRefresherContent
                        pullingIcon={chevronDownCircleOutline}
                        pullingText="Tire para refrescar"
                        refreshingSpinner="circles"
                        refreshingText="Refrescando...">
                        </IonRefresherContent>
                </IonRefresher>
                <span className="sg-bg-grad-vertical sg-py-4 sg-w-full sg-text-center sg-font-bold" style={{display:'block', color:'white'}}>PERFIL</span>
                <div className="sg-text-left sg-flex sg-flex-col sg-w-full sg-items-left sg-px-4 sg-py-4 sg-space-y-4">
                    <div className="sg-profile-image sg-mx-auto" onClick={openSelectImage}>

                        <img className={"sg-profile-image-display sg-h-20 sg-w-20 sg-text-center sg-rounded-full "+(imageThumbLoading ? 'sg-animate-pulse sg-bg-gray-200' : '')} alt="" src={isNewPfp ? user.pfp_path : (user.pfp_path ? (user.pfp_path.startsWith('https') ? user.pfp_path : process.env.REACT_APP_BASE_URL + '/' + user.pfp_path) : DEFUALT_PFP_PATH)} />
                        <div className="sg-profile-image-upload sg-text-center sg-rounded-full sg-shadow-lg sg-bg-gray sg-text-gray-500 sg-bg-gray-300 sg-h-9 sg-w-9 sg--bottom-0 sg--right-2">
                            <IonIcon className="sg-h-6 sg-w-6 sg-pl-0.5 sg-absolute sg-top-1/2 sg-left-1/2 sg-transform sg--translate-x-1/2 sg--translate-y-1/2" ios={cloudUploadOutline} md={cloudUploadOutline}/>
                        </div>
                    </div>
                    <IonText>Rol: {user.roles[0] ? user.roles[0].display_name : ''}</IonText>
                    <IonLabel>Nombre completo</IonLabel>
                    <IonInput type="text" className="sg-input" value={user.name} onIonChange={e =>  dispatch({ type: 'SAVE_USER_INFO', payload:{user:{...user, name:e.detail.value!} } }) } disabled={loading}/>
                    <IonLabel>Teléfono</IonLabel>
                    <IonInput type="number" className="sg-input" value={user.phone} onIonChange={e =>  dispatch({ type: 'SAVE_USER_INFO', payload:{user:{...user, phone:e.detail.value!} } }) } disabled={loading}/>
                    <IonLabel>País</IonLabel>
                    <IonInput type="text" className="sg-input" value={user.country} onIonChange={e =>  dispatch({ type: 'SAVE_USER_INFO', payload:{user:{...user, country:e.detail.value!} } }) } disabled={loading}/>
                </div>
                { loading &&
                        <div className="sg-w-full sg-text-center">
                            <IonSpinner name="dots" color="primary"/>
                        </div>
                }
                <div className="sg-text-left sg-flex sg-flex-col sg-w-full sg-items-center sg-px-4 sg-py-4 sg-space-y-4">
                    <IonButton className="sg-bg-grad sg-rounded-full" style={{width:'220px', textTransform:'uppercase'}} onClick={ () => connection.connected ? tryProfileUpdate() : setShowToast(true) } disabled={loading || !connection.connected }>Guardar</IonButton>
                </div>
                <IonToast
                    isOpen={showToast}
                    onDidDismiss={() => setShowToast(false)}
                    cssClass="sg-font-normal sg-text-base sg-text-center" //text-center doesnt quite align the message to the center of the toast becuase of the close button.
                    //the message needs left padding
                    message="           Este formulario no está disponible." //this is a really dumb way of adding left padding to the message but ionic has forced my hand
                    duration={3000}
                    color="dark"
                    buttons={[
                    {
                        icon:close,
                        role: 'cancel',
                        handler: () => {
                        //console.log('Cancel clicked');
                        }
                    }
                    ]}
                />
            </IonContent>
        </IonPage>
    );
};

export default Profile;
