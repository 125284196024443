const initialState:any = [];

export const SAVE_FORM = 'SAVE_FORM'
export const UPDATE_FORM = 'UPDATE_FORM'
export const SAVE_FORM_FIELDS = 'SAVE_FORM_FIELDS'
export const UPDATE_FIELD = 'UPDATE_FIELD'
export const UPDATE_FIELD_WITH_CHECK_BOX = 'UPDATE_FIELD_WITH_CHECK_BOX'
export const SET_FORM_DIRTY = 'SET_FORM_DIRTY'
export interface Forms{
    forms: Array<Form>
};
export interface Form{
    form:any
};
export interface SaveFormAction{
    type: typeof SAVE_FORM
    payload: Form
};
export interface DirtyForm{
    type: typeof SET_FORM_DIRTY
    payload: {formId:number}
};
export interface UpdateFormAction{
    type: typeof UPDATE_FORM
    payload: Form
};
export interface SaveFormFieldsAction{
    type: typeof SAVE_FORM_FIELDS
    payload: {form:any};
    formId:number;
};
export interface UpdateFieldAction{
    type: typeof UPDATE_FIELD;
    payload: {value:any, field:any};
    formId:number;
    pageId:number;
    componentId:number;
    stageIndex:number;
    plotIndex:number;
};
export interface UpdateFieldCheckBoxAction{
    type: typeof UPDATE_FIELD_WITH_CHECK_BOX
    payload: {value:any};
    formId:number;
    pageId:number;
    componentId:number;
    optionIndex:number;
    stageIndex:number;
    plotIndex:number;
};


  // Use the initialState as a default value
export default function formsReducer(state:any = initialState, action:SaveFormAction|UpdateFormAction|UpdateFieldAction|UpdateFieldCheckBoxAction|SaveFormFieldsAction|DirtyForm) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_FORM':
            let forms = state ? state : [];
            let foundFormA:boolean = false;
            let foundFormIndexA:number = 0;
            for (const form of forms) {
                if(form.id === action.payload.form.id){
                    foundFormA=true;
                    forms[foundFormIndexA] = action.payload.form;
                    break;
                }
                foundFormIndexA++;
            }
            forms = foundFormA ? forms: [...forms, action.payload.form];
            return forms;
            //return [...forms, action.payload.form];
        case 'SET_FORM_DIRTY':
            let newFormsD:any =  state ? state : [];
            newFormsD[action.payload.formId].dirty = true;
            return newFormsD;
        case 'UPDATE_FORM':
            return action.payload.form;
        case 'SAVE_FORM_FIELDS':
            let formsTwo = state ? state : [];
            let foundForm:boolean = false;
            let foundFormIndex:number = 0;
            for (const form of formsTwo) {
                if(form.id === action.formId){
                    foundForm=true;
                    formsTwo[foundFormIndex] = action.payload.form;
                    break;
                }
                foundFormIndex++;
            }
            formsTwo = foundForm ? formsTwo: [...formsTwo, action.payload.form];
            return formsTwo;
        case 'UPDATE_FIELD':
            let newForms:any =  state ? state : []
            if(newForms[action.formId]){
                if(!newForms[action.formId].formFields.data[action.pageId].components[action.componentId].userInput){
                    newForms[action.formId].formFields.data[action.pageId].components[action.componentId].userInput = {stages:[]};
                }
                if(!newForms[action.formId].formFields.data[action.pageId].components[action.componentId].userInput.stages[action.stageIndex]){
                    newForms[action.formId].formFields.data[action.pageId].components[action.componentId].userInput.stages[action.stageIndex] = [];
                }
                newForms[action.formId].formFields.data[action.pageId].components[action.componentId].userInput.stages[action.stageIndex][action.plotIndex] = action.payload;
            }
            return newForms
        case 'UPDATE_FIELD_WITH_CHECK_BOX':
            let newFormsCheckBoxes = state ? state : []
            if(newFormsCheckBoxes[action.formId]){
                if(!newFormsCheckBoxes[action.formId].formFields.data[action.pageId].components[action.componentId].values[action.optionIndex].userInput){
                    newFormsCheckBoxes[action.formId].formFields.data[action.pageId].components[action.componentId].values[action.optionIndex].userInput = {stages:[]};
                }
                if(!newFormsCheckBoxes[action.formId].formFields.data[action.pageId].components[action.componentId].values[action.optionIndex].userInput.stages[action.stageIndex]){
                    newFormsCheckBoxes[action.formId].formFields.data[action.pageId].components[action.componentId].values[action.optionIndex].userInput.stages[action.stageIndex] = [];
                }
                newFormsCheckBoxes[action.formId].formFields.data[action.pageId].components[action.componentId].values[action.optionIndex].userInput.stages[action.stageIndex][action.plotIndex] = action.payload;
            }
            return newFormsCheckBoxes
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}