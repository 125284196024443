import { FC } from "react";
import { IonTextarea} from "@ionic/react";
import { useDispatch } from "react-redux";

export interface SgFormFieldProps {
    field:any;
    props:any;
    formIndex:number;
    pageIndex:number;
    componentIndex:number;
    stageIndex:number;
    plotIndex:number;
    is_satisfaction:any;

}

const SgTextarea: FC<any> = ({
    field,
    formIndex,
    pageIndex,
    componentIndex,
    stageIndex,
    plotIndex,
    is_satisfaction,
    ...props
    }) => {
        const dispatch = useDispatch()
        const updateTextField = (value:any):any => {
            dispatch({ type: 'UPDATE_FIELD', payload:{value:value, field:field.tema}, formId:formIndex, pageId:pageIndex, componentId:componentIndex, stageIndex:stageIndex, plotIndex:plotIndex });
        };
        return(
            <div className="sg-px-4 sg-w-full sg-max-w-md sg-mx-auto">
                <label htmlFor={field.key}>{(field.is_satisfaction === "Formulario de Satisfacción") && field.label }</label>
                <div className="sg-h-4"></div>
                <IonTextarea
                {...props}
                id={field.key}
                autoGrow={true}
                className="sg-pb-12 sg-placeholder-gray-600 sg-px-2 sg-w-full rounded-xl h-12 px-4 sg-bg-transparent sg-appearance-none sg-border-black sg-border sg-border-solid sg-rounded-xl focus-within:sg-border-sgBlue focus-within:sg-border-2"
                onIonInput={ (e) => updateTextField((e.target as HTMLInputElement).value) }
                maxlength={field.validate && field.validate.maxLength}/>
            </div>
        );
};

export default SgTextarea;
