import { IonCard, IonCardContent, IonSpinner } from '@ionic/react';
import React from 'react';
//import './TourCard.css';

const FullscreenLoader: React.FC = () => {
    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh', width:'100vw', position:'fixed', top:'0', left:'0', backgroundColor:'rgba(128, 128, 128, .5)', zIndex:999999}}>
            <IonCard style={{marginLeft:'auto',marginRight:'auto', width:'40%', minWidth:'200px', height:'12vh', minHeight:'90px'}}>
                <IonCardContent className="sg-text-center">
                    <IonSpinner name="crescent" /> Cargando...
                </IonCardContent>
            </IonCard>
        </div>
    );
};

export default FullscreenLoader;
