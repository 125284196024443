import React,{ useState, } from 'react';
import {IonContent, IonInput, IonItem, IonLabel, IonPage, IonProgressBar, IonText } from '@ionic/react';
import { IonCard, IonCardContent, IonIcon, IonButton, IonHeader, IonToolbar, IonButtons, IonBackButton, } from '@ionic/react';


import { useAuth } from '../../hooks/useAuth';
import { useValidation } from '../../hooks/useValidation';
import { checkmarkDoneCircleOutline } from 'ionicons/icons';

const Reset: React.FC = () => {
    const { reqPassResetEmail } = useAuth();
    const { hasError, getErrorMessage, setValidations } = useValidation();
    const [email, setEmail] = useState<string>();
    const [loading, setLoading] = useState(false);
    //const history = useHistory();
    //const dispatch = useDispatch();
    const [resetData, setResetData]:any = useState<string>();
    /* useEffect(()=>{
        setResetData({success:true, data:'This is a test message just to see things'})
    }) */
    const tryResetReq = async () => {
        setLoading(true);
        try{
            setResetData(await reqPassResetEmail(''+email));
            console.log(resetData && resetData.success);
            console.log(!resetData || !resetData.success);
            
        }catch(error){
            setValidations(error);
        }
        setLoading(false);
    }
    return (
        <IonPage className="auth-page">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div style={{paddingTop:'clamp(16vw, 300px, 20vh)'}}>
                    <IonText>
                        <h1 style={{textAlign:'center'}}>Restablecer Contraseña</h1>
                    </IonText>
                    <IonCard>
                        <IonCardContent>
                        {
                            (resetData && resetData.success) ? 
                            <div className="sg-text-center sg-flex sg-flex-col sg-items-center">
                                <IonIcon icon={checkmarkDoneCircleOutline} className="sg-big-success-checkmarks" />
                                <IonText className="sg-text-xl">{resetData.data}</IonText>
                            </div> 
                            :
                            <div>
                                <IonLabel>Email</IonLabel>
                                <IonInput type="email" value={email} placeholder="Ingresa tu email" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
                                { hasError('email') ? <IonItem lines="none"><IonText className="validation-error">{ getErrorMessage('email')}</IonText></IonItem> : '' }
                                <IonButton color="primary" expand="block" onClick={async (e) => {await tryResetReq()}}>
                                    <IonText>Restablecer</IonText>
                                </IonButton>
                            </div>
                        }
                        </IonCardContent>
                        { loading ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
                        
                    </IonCard>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Reset;
