const initialState:any = {
    notificationsData:[],
    totalNotificationCount:0,
    checkedNotificationCount:0,
};
export const SAVE_NOTIFS = 'SAVE_NOTIFS'
export const SAVE_TOTAL_NOTIF_COUNT = 'SAVE_TOTAL_NOTIF_COUNT'
export const SAVE_CHECKED_NOTIF_COUNT = 'SAVE_CHECKED_NOTIF_COUNT'
export interface Notifications{
    notifications: Array<Notification>
};
export interface Notification{
    notification:any
};
export interface SaveNotificationsAction{
    type: typeof SAVE_NOTIFS
    payload: Notifications
};
export interface UpdateTotalNotificationsCountAction{
    type: typeof SAVE_TOTAL_NOTIF_COUNT
    payload: {count:number}
};
export interface UpdateCheckedNotificationsCountAction{
    type: typeof SAVE_CHECKED_NOTIF_COUNT
    payload: {count:number}
};

  // Use the initialState as a default value
export default function notificationReducer(state:any = initialState, action:SaveNotificationsAction|UpdateTotalNotificationsCountAction|UpdateCheckedNotificationsCountAction) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_NOTIFS':
            return {...state, notificationsData:action.payload.notifications};
        case 'SAVE_TOTAL_NOTIF_COUNT':
            return {...state, totalNotificationCount:action.payload.count};
        case 'SAVE_CHECKED_NOTIF_COUNT':
            return {...state, checkedNotificationCount:action.payload.count};
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}