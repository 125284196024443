import { useState, } from 'react';
import { useHttpReq } from './useHttpReq';
import { useDispatch, useSelector } from 'react-redux';
import { Storage } from '@capacitor/storage';

export function useTours() {
    const { makeHttpGet, makeHttpPost, makeHttpPut } = useHttpReq();

    const reqTour = async (id:number):Promise<any> => { 
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let profileData = await makeHttpGet(`/users/self/tours`, authHeader);
        let tour = profileData.data.data;
        
        return tour
    }

    const reqActivateInvite = async (code:string) => {
        const authToken = await Storage.get({key:'authToken'});

        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let inviteData = await makeHttpPost(`/invites`, {code:code}, authHeader);
        let invite = inviteData.data.data;

        return invite;
    }

    const reqTourInfo = async (id:number):Promise<any> => {
        const authToken = await Storage.get({key:'authToken'});

        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let tour = await makeHttpGet(`/tours/${id}/details`, authHeader);
        let tourData = tour.data.data;

        return tourData;
    }

    return {
        reqTour,
        reqActivateInvite,
        reqTourInfo
    };
    

}