import {
    IonBadge,
    IonContent,
    IonIcon,
    IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import React from 'react';
import { useLocation } from 'react-router-dom';
import { homeOutline, home, settings, cogOutline, logOutOutline, logOut, person, book, bookOutline, personOutline, timeOutline, time } from 'ionicons/icons';
import './Menu.css';
import { useSelector } from 'react-redux';

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
    notifBadge?:boolean;
}

const appPages: AppPage[] = [
    {
        title: 'Página principal',
        url: '/main',
        iosIcon: homeOutline,
        mdIcon: home
    },
    {
        title: 'Perfil',
        url: '/user/self',
        iosIcon: personOutline,
        mdIcon: person
    },
    // {
    //     title: 'Notifications',
    //     url: '/notifs',
    //     iosIcon: notificationsOutline,
    //     mdIcon: notifications,
    //     notifBadge:true
    // },
    {
        title: 'Contacto',
        url: '/contacto',
        iosIcon: bookOutline,
        mdIcon: book
    },
    {
        title: 'Historial',
        url: '/history',
        iosIcon: timeOutline,
        mdIcon: time
    },
    /* {
        title: 'Results',
        url: '/results',
        iosIcon: pieChartOutline,
        mdIcon: pieChart
    }, */
    // {
    //     title: 'Ajustes',
    //     url: '/settings/Settings1',
    //     iosIcon: cogOutline,
    //     mdIcon: settings
    // },
    {
        title: 'Cerrar sesión',
        url: '/auth/logout',
        iosIcon: logOutOutline,
        mdIcon: logOut
    },
];

const Menu: React.FC = () => {
    const location = useLocation();
    const notifCount = useSelector((state:any) => { return (state.notifications.totalNotificationCount > state.notifications.checkedNotificationCount) ? state.notifications.totalNotificationCount - state.notifications.checkedNotificationCount : 0});
    return (
    <IonMenu contentId="main" type="overlay" side="end">
        <IonContent>
        <IonList id="inbox-list" className="sg-h-full sg-flex sg-flex-col sg-justify-center sg-max-h-screen-75 sg-border-b-0">
            <div style={{paddingLeft:'20px',paddingBottom:'8px'}}>
                <IonImg src={'/assets/images/syngenta_logo.png'} alt="logo" style={{width:'120px'}}/>
            </div>
            <IonNote>Syngenta App</IonNote>
            {appPages.map((appPage, index) => {
            return (
                <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon className="sg-text-sgSecondary" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                    {appPage.notifBadge &&
                        <IonBadge color="danger" className={notifCount ? '' : 'sg-hidden'}>{notifCount}</IonBadge>
                    }
                </IonItem>
                </IonMenuToggle>
            );
            })}
        </IonList>

        </IonContent>
    </IonMenu>
    );
};

export default Menu;
