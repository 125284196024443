import React, { useState } from 'react'
import Collapsible from 'react-collapsible';
import { useNotifs } from '../../hooks/useNotifs';
import { useDispatch } from 'react-redux';
import './NotificationLine.css';
import './NotificationItemLine.css';
interface ContainerProps {
    notif:any;
}

const NotificationItemLine: React.FC<ContainerProps> = ({ notif }) => {
    const {markRead} = useNotifs();
    const [message, setMessage] = useState('Abrir');
    let dispatch = useDispatch();

    const setShow = () => {
        setMessage('Abrir')
    }

    const setHide = () => {
        if (!notif.seen) {
            markRead(notif.id).then((seen)=>{
                console.log(seen);
                dispatch({type: 'SAVE_CHECKED_NOTIF_COUNT', payload:{seen}});
            })
            .catch((error)=> {
                console.log(error);
            });
            notif.seen = true;
        }
        setMessage('Cerrar')
    }

    return (
        <div className={"sg-w-full sg-pt-8 sg-text-left sg-px-4 sg-py-4 " + (notif.seen ? "sg-bg-green-100" : "")}>
        <div className="sg-w-full">
                <Collapsible transitionTime={300} onTriggerOpening={setHide} onTriggerClosing={setShow} easing="ease-out" trigger={
                    <div className="sg-flex sg-flex-row sg-w-80 sg-items-start">
                        <span className={"sg-text-sgBlue sg-text-2xl sg-no-underline sg-font-bold sg-min-h-6 sg-block sg-mb-2 truncate" + (notif.title ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{notif.title}</span>
                        {/* {notif.seen == 'false' ? <IonBadge color="danger" className="sg-ml-3 sg-mt-1.5">New</IonBadge> : <div></div>} */}
                        <span className="sg-my-2 sg-text-sgBlue sg-text-base sg-ml-auto">{message}</span>
                    </div>
                }>
                    <p className={"sg-my-2 sg-text-gray-500 sg-text-base sg-min-h-6 sg-min-w-full" + (notif.message ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{notif.message}</p>
                </Collapsible>
        </div>
    </div>
    );
};

export default NotificationItemLine;
