import { useState, useEffect } from "react";
import { useCamera } from '@capacitor-community/react-hooks/camera';
import { useFilesystem, base64FromPath } from '@capacitor-community/react-hooks/filesystem';
import { useStorage } from '@capacitor-community/react-hooks/storage';
import { isPlatform } from '@ionic/react';
import { Camera, CameraResultType, CameraSource, Photo }  from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { Directory } from '@capacitor/filesystem';



export interface SgPhoto {
    filepath: string;
    webviewPath?: string;
}

const PHOTO_STORAGE = "photos";
export function usePhotoGallery() {
    const { get, set } = useStorage();
    const { deleteFile, readFile, writeFile } = useFilesystem();
    const savePicture = async (photo: Photo, fileName: string): Promise<SgPhoto> => {
        let base64Data: string;
        // "hybrid" will detect Cordova or Capacitor;
        if (isPlatform('hybrid')) {
            const file = await readFile({
                path: photo.path!
            });
            base64Data = file.data;
        } else {
            base64Data = await base64FromPath(photo.webPath!);
        }
        const savedFile = await writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Data
        });
    
        if (isPlatform('hybrid')) {
            // Display the new image by rewriting the 'file://' path to HTTP
            // Details: https://ionicframework.com/docs/building/webview#file-protocol
            return {
                filepath: savedFile.uri,
                webviewPath: Capacitor.convertFileSrc(savedFile.uri),
            };
        }
        else {
          // Use webPath to display the new image instead of base64 since it's
          // already loaded into memory
            return {
                filepath: fileName,
                webviewPath: photo.webPath
            };
        }
    };
    const cameraHookHack = useCamera();
    const { getPhoto } = isPlatform('hybrid') ? cameraHookHack : Camera;
    const [photos, setPhotos] = useState<SgPhoto[]>([]);
    useEffect(() => {
        const loadSaved = async () => {
            const photosString = await get('photos');
            const photosInStorage = (photosString ? JSON.parse(photosString) : []) as SgPhoto[];
            // If running on the web...
            if (!isPlatform('hybrid')) {
                for (let photo of photosInStorage) {
                const file = await readFile({
                    path: photo.filepath,
                    directory: Directory.Data
                });
                // Web platform only: Load photo as base64 data
                photo.webviewPath = `data:image/jpeg;base64,${file.data}`;
                }
            }
            setPhotos(photosInStorage);
        };
        loadSaved();
    }, [get, readFile]);
    const takePhoto = async () => {
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 100
        });
        const fileName =  new Date().getTime()+'.jpeg'
        const savedFileImage = await savePicture(cameraPhoto, fileName);
        const newPhotos = [savedFileImage, ...photos];
        setPhotos(newPhotos);
        set(PHOTO_STORAGE, JSON.stringify(newPhotos));
    };

    const deletePhoto = async (photo: SgPhoto) => {
        // Remove this photo from the Photos reference data array
        const newPhotos = photos.filter(p => p.filepath !== photo.filepath);
        
        // Update photos array cache by overwriting the existing photo array
        set(PHOTO_STORAGE, JSON.stringify(newPhotos));
        
        // delete photo file from filesystem
        const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1);
        await deleteFile({
            path: filename,
            directory: Directory.Data
        });
        setPhotos(newPhotos);
    };
    
    const addFromDevice = async ():Promise<string>=>{ 
        const cameraPhoto = await getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt,
            quality: 100
        }).catch((error):any=>{
            console.log(error.name)
            return 0;
        });
        //console.log(cameraPhoto)
        if(cameraPhoto === 0){
            return 'flag';
        }
        //console.log(cameraPhoto);
        if( !(/(png|jpeg|jpg)/.test(cameraPhoto.format)) ){
            throw new Error('Invalid image, must be .png, .jpeg, or .jpg');
            
        }
        let base64Data: string;
        // "hybrid" will detect Cordova or Capacitor;
        if (isPlatform('hybrid')) {
            const file = await readFile({
                path: cameraPhoto.path!
            });
            //png|jpeg|jpg
            base64Data = 'data:image/'+cameraPhoto.format+';base64, ' +  file.data;
        } else {
            base64Data = await base64FromPath(cameraPhoto.webPath!);
        }
        //console.log(base64Data.slice(base64Data.length-26, base64Data.length));
        
        let base64length = base64Data ? base64Data.length : 0; 
        let fileSize:number = Math.ceil((base64length * (3/4)) - 2);
        if(fileSize > 15000000){
            throw new Error('Image too large. Maximum 15MB');
        }
        
        return base64Data;
    }


    return {
        deletePhoto,
        photos,
        takePhoto,
        addFromDevice,
    };

    

}