import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
    IonRouterOutlet,
} from '@ionic/react';

import Login from './Login';
import Register from './Register';
import Reset from './Reset';
import Logout from './Logout';
import { IonReactRouter } from '@ionic/react-router';
import './AuthPages.css';

const AuthRoot: React.FC = () => {
    return (
        <IonReactRouter>
            <IonRouterOutlet id="auth">
                <Route path="/auth/logout" component={Logout} exact={true}/>
                <Route path="/auth/login" component={Login} exact={true}/>
                <Route path="/auth/register" component={Register} exact={true} />
                <Route path="/auth/reset" component={Reset} exact={true} />
                <Route path="/auth" render={() => <Redirect to="/auth/login" />} exact={true} />
                <Route path="/" render={() => <Redirect to="/auth/login" />} exact={true} />
                <Route path="/main/" render={() => <Redirect to="/auth/login" />} />
                {/* <Route path="/settings/" render={() => <Redirect to="/auth/login" />} /> */}
                {/* <Route path="/auth/logout" render={() => <Redirect to="/auth/login" />} /> */}
            </IonRouterOutlet>
        </IonReactRouter>
    );
};

export default AuthRoot;
