import React,{ useEffect, useState, } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonMenuButton, IonButton, IonIcon, IonBackButton, IonButtons, IonRefresher, IonRefresherContent, IonImg } from '@ionic/react';

import { chevronDownCircleOutline } from 'ionicons/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useProfile } from '../../hooks/useProfile';
import { useParams } from 'react-router';
import FormListItem from '../../components/forms_list/FormListItem';
import { Conntection } from '../../redux/reducers/DeviceStatusReducer';


const TourFormsList: React.FC = () => {
    let { id }:any = useParams();
    const {reqMyTours} = useProfile();
    /* const [notifs, setNotifs] = useState<any>(
        [
            {
                name:'',
                message:''
            },
            {
                name:'',
                message:''
            },
    ]); */
    let tour = useSelector((state:any) => {
        //console.log(state);
        if(state.tours){
            for (const item of state.tours) {
                if(item.id == id){
                    return item
                }
            }
        }
    });

    const connection:Conntection = useSelector((state:any) => state.deviceStatus.connection);

    let formIds = useSelector((state:any) => {
        if(state.forms){
            return state.forms.map((formElm:any) => {
                return formElm.id;
            });
        }else{
            return [];
        }
    });

    let dispatch = useDispatch();

    const [fresh, setFresh] = useState(true);
    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        if(fresh){
            if(!tour){
                doRefresh();
                setFresh(false);
            }
        }
    }, [setFresh, fresh, tour, dispatch]);

    const doRefresh = (event:any=null) => {
        reqMyTours().then((resp:any) => {
            console.log(resp);
            dispatch({ type: 'SAVE_TOURS', payload:{tours:resp} });
            if(event){
                event.detail.complete();
            }
        }).catch((error:any) => {
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }

    /* let contacts = Array.apply(null, Array(8)).map(function (x, i) {
        return {
            name:'James Alberts',
            title: 'Survey Director',
            tel:'(303) 555-0105',
            email:'jame.alberts@example.com',
            photo_path:process.env.REACT_APP_BASE_URL + '/img/YrRqcygTfho.jpg',
            font:0,
            country:'México',
            description:'Enim labore Lorem occaecat anim mollit voluptate.'
        };
    }); */

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    {/* <IonTitle size="large">Formularios</IonTitle> */}
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Formularios</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                    <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Tire para refrescar"
                    refreshingSpinner="circles"
                    refreshingText="Refrescando...">
                    </IonRefresherContent>
                </IonRefresher>
                <div className="sg-text-center sg-flex sg-flex-col sg-w-full sg-items-center sg-py-4 sg-special-divide sg-divide-x-0 sg-divide-y-2 sg-divide-sgSecondary sg-divide-solid sg-divide-opacity-80">
                    {(tour && tour.forms && (formIds || formIds===[])) && tour.forms.map((form:any, formIndex:number) => <FormListItem form={form} key={form.id} tourId={id} showIcon={true} formIds={formIds} isConnected={connection.connected} date={tour.date} />)}
                </div>
                {((tour && tour.forms) && tour.forms.length <= 0) &&
                    <div className="sg-flex sg-justify-center sg-items-center sg-flex-col sg-pt-24">
                        <h3 className="sg-text-gray-700">No hay formularios disponibles.</h3>
                        <IonImg src={'/assets/images/syngenta_logo_black.png'} className="sg-h-14 sg-w-60 sg-opacity-50"/>
                    </div>
                }
            </IonContent>
        </IonPage>
    );
};

export default TourFormsList;
