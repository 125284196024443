const initialState = {
    authToken: '',
    userName: '',
}

export const SAVE_AUTH = 'SAVE_AUTH'
export const CLEAR_AUTH = 'CLEAR_AUTH'
export interface AuthData{
    jwToken: string
};
export interface SaveAuthAction{
    type: typeof SAVE_AUTH
    payload: AuthData
};
export interface ClearAuthAction{
    type: typeof CLEAR_AUTH
    payload: any
};

  // Use the initialState as a default value
export default function loginReducer(state:any = initialState, action:SaveAuthAction|ClearAuthAction) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case 'SAVE_AUTH':
            return {
                authToken: action.payload.jwToken,
            };
        case 'CLEAR_AUTH':
            return {
                authToken: '',
                userName: ''
            };
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}