import { useHttpReq } from './useHttpReq';
import { Storage } from '@capacitor/storage';

export function useNotifs() {
    const { makeHttpGet, makeHttpPut } = useHttpReq();

    const reqUseruseNotifs = async ():Promise<any> => { 
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let notifsData = await makeHttpGet(`/notifications`, authHeader);
        let notifs = notifsData.data.data;
        
        return notifs
    }

    const markRead = async (notifId:number):Promise<any> => { 
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}
        console.log(authHeader);

        let notifsData = await makeHttpPut(`/notifications/mark_read`, {id: notifId}, authHeader);
        let done = notifsData.data.data;
        
        return done
    }

    return {
        reqUseruseNotifs,
        markRead
    };
    

}