import React,{ useCallback, useEffect, useState, } from 'react';
import { IonBackButton, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonImg} from '@ionic/react';
import DOMPurify from 'dompurify';
import { useTours } from '../../hooks/useTours';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FullscreenLoader from '../../components/FullscreenLoader';

const Tour: React.FC = () => {
    let { id }:any = useParams();
    /* const { reqTour } = useTours();
    reqTour(id); */
    let validObjectiveText = false;

    const tourRedux:any = useSelector((state:any) => { 
        console.log('hi');
        console.log(state.tours);
        if(state.tours){
            for (const tourItem of state.tours) {
                console.log(id);
                console.log(tourItem.id);
                if(tourItem.id == id){
                    validObjectiveText = tourItem.objectives!='<p><br></p>';
                    return tourItem;
                }
            }
        }
    });
    return (
        <IonPage>
            { tourRedux && 
            <div>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" text="Atrás"/>
                        </IonButtons>
                        <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                        {/* <IonTitle>{tourRedux.name}</IonTitle> */}
                        <IonMenuButton slot="end"></IonMenuButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent fullscreen>
                    <IonHeader collapse="condense">
                        <IonToolbar>
                            <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Información General</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    {validObjectiveText ? 
                        <div className="sg-px-4 sg-pt-2"  dangerouslySetInnerHTML={{__html:DOMPurify.sanitize(tourRedux.objectives) }}></div>
                    :
                        <div className="sg-flex sg-justify-center sg-items-center sg-flex-col sg-pt-24">
                            <h3 className="sg-text-gray-700">No hay description.</h3>
                            <IonImg src={'/assets/images/syngenta_logo_black.png'} className="sg-h-14 sg-w-60 sg-opacity-50"/>
                        </div>
                    }
                    
                </IonContent>
            </div>

            }
            { !tourRedux && <FullscreenLoader/> }
        </IonPage>
    );
};

export default Tour;
