import { FC } from "react";
import { IonInput, isPlatform} from "@ionic/react";
import { useDispatch } from "react-redux";
import { Keyboard } from '@capacitor/keyboard';

//type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';

export interface SgFormFieldProps {
    field:any;
    props:any;
    formIndex:number;
    pageIndex:number;
    componentIndex:number;
    stageIndex:number;
    plotIndex:number;
    is_satisfaction:any;
    descripcion:any;
}

const SgInput: FC<any> = ({
    field,
    formIndex,
    pageIndex,
    componentIndex,
    stageIndex,
    plotIndex,
    is_satisfaction,
    key,
    descripcion,
    ...props
    }) => {
        const dispatch = useDispatch()
        const updateTextField = (event:any, target:any):any => {
            //console.log(event);
            //console.log(value);
            dispatch({ type: 'UPDATE_FIELD', payload:{value:target.value, field:field.tema}, formId:formIndex, pageId:pageIndex, componentId:componentIndex, stageIndex:stageIndex, plotIndex:plotIndex });
        };
        const keyDownTextField = (event:React.KeyboardEvent<HTMLIonInputElement>):any => {
            if(event.key === 'Enter'){
                event.preventDefault();
                // @ts-ignore
                event.target.blur();
                if (isPlatform('hybrid')) {
                    Keyboard.hide();
                }
            }
        };

        return(
            <div className="sg-px-4 sg-w-full sg-max-w-md sg-mx-auto" key={field.key}>
                <label htmlFor={field.key}>{field.descripcion}</label> 
                <div className="sg-h-4"></div>
                <IonInput {...props} onInput={ (e) => updateTextField(e,e.target) } onKeyDown={(e) => keyDownTextField(e)} />
            </div>
        );
};

export default SgInput;
