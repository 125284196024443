import { IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonImg, IonMenuButton, IonPage, IonRefresher, IonRefresherContent, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
//import FullscreenLoader from '../../components/FullscreenLoader';
import { useForms } from '../../hooks/useForms';

const Results:React.FC = () => {
    let { tour_form_id }:any = useParams();
    let { getResults, getResultsPDF } = useForms();

    const [fresh, setFresh] = useState(true);
    const dispatch =  useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);

    const [isFlashDisplaying, setIsFlashDisplaying]:any = useState(false);
    const [errorText, setErrorText]:any = useState('Can not generate pdf of results.');
    const [success, setSuccess]:any = useState(false);

    let tourForm = useSelector((state:any) => {
        console.log(state);
        if(state.results){
            for (const resultsItem of state.results) {
                if(resultsItem.id == tour_form_id){
                    return resultsItem;
                }
            }
        }
    })

    const doRefresh = (event:any=null) => {
        getResults(tour_form_id).then((resp:any) => {
            dispatch({ type: 'SAVE_FORM_RESULTS', payload:{results:resp}});
            if(event){
                event.detail.complete();
            }
        }).catch((error:any) => {
            console.log(error);
            if(event){
                event.detail.complete();
            }
        });
    }

    const requestPDF = () => {
        getResultsPDF(tour_form_id).then((resp:any)=>{
            console.log(resp);
            setSuccess(true);
            setTimeout(
                () =>setSuccess(false), 
                3000
            );
        }).catch((error:any) => {
            console.log(error);
            flashError();
        });
    }

    const flashError = ():any => {
        setIsFlashDisplaying(true)
        setTimeout(
            () => setIsFlashDisplaying(false), 
            3000
        );
    }

    useEffect(()=>{
        if(fresh){
            if(!tourForm){
                doRefresh();
                setFresh(false);
            }
        }
    }, [setFresh, fresh]);

    console.log(tourForm)
    return (
        <IonPage>
        { isFlashDisplaying && 
        <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-red-300 sg-border-red-800 sg-border-4 sg-border-solid sg-text-red-800 sg-z-50 sg-rounded-b-2xl">
            <span><strong>Error:</strong> {errorText}</span>
        </div>
        }
        { success && 
        <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-green-300 sg-border-gree-800 sg-border-4 sg-border-solid sg-text-green-800 sg-z-50 sg-rounded-b-2xl">
            <span><strong>PDF Email Sent</strong></span>
        </div>
        }
        <div className="sg-contents">
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" text="Atrás"/>
                    </IonButtons>
                    <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                    {/* <IonTitle>Resultados</IonTitle> */}
                    <IonMenuButton slot="end"></IonMenuButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large" className="sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-text-white sg-text-center sg-font-regular sg-text-base">Resultados</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonRefresher slot="fixed" onIonRefresh={doRefresh} pullMin={120} pullMax={window.innerHeight * 0.5}>
                    <IonRefresherContent
                    pullingIcon={chevronDownCircleOutline}
                    pullingText="Tire para refrescar"
                    refreshingSpinner="circles"
                    refreshingText="Refrescando...">
                    </IonRefresherContent>
                </IonRefresher>
                { tourForm &&
                    <div className="sg-contents">
                        <div className="sg-text-center sg-text-gray-500 sg-px-6">
                            {<h1 className="sg-font-bold sg-text-gray-600">{tourForm.tour.name}</h1>}
                            <p>{tourForm.title}</p>
                            {/* { results.tours[0].tourContactCards && 
                                <div className="sg-contents">
                                    <p>Productor <strong className="sg-text-sgBlue sg-capitalize">{results.tours[0].tourContactCards[0].name}</strong></p>
                                    <p>Contacto <strong className="sg-text-sgBlue">{results.tours[0].tourContactCards[0].phone}</strong></p>
                                </div>
                            } */}
                            <div className="sg-text-center">
                                {tourForm.form.results ? <p>Hora <strong className="sg-text-sgBlue">{tourForm.form.results[0].updated_at}</strong></p> : <div></div>}
                            </div>
                        </div>
                        <div className="sg-divide-x-0 sg-divide-sgSecondary sg-divide-y-2 sg-divide-solid sg-space-y-6 sg-px-6 sg-pb-8">
                            {tourForm.form.results ? <div></div> : <p className="sg-mt-16 sg-text-center">Nadie ha llenado este formulario aún.</p>}
                            { tourForm.form.results && tourForm.form.results[0].data ? 
                            tourForm.form.results[0].data.map((dataPoint:any) => { 
                                /* return ( 
                                    <pre>
                                        {JSON.stringify(dataPoint, null, 4)}
                                    </pre> 
                                );  */
                                if(dataPoint.type === 'number' ){
                                    return (
                                    <div className="sg-pt-6">
                                        <h1 className="sg-text-gray-600 sg-text-lg sg-my-0 sg-text-center sg-w-full sg-pb-2">{dataPoint.name}</h1>
                                        <Bar
                                            type={Bar}
                                            data={{
                                                labels:dataPoint.stages.filter((stage:any) => stage.plots).flatMap((stage:any) => { 
                                                    return stage.plots.filter((plot:any) => plot).map((plot:any) => {
                                                        return `${stage.name},${plot.plotName}`;
                                                    });
                                                }),
                                                datasets: [
                                                    {
                                                        label: dataPoint.name,
                                                        backgroundColor: ['#01558c', '#06936E', '#BBD048','#193b6c', '#36abe0', '#549a8b'],
                                                        data: dataPoint.stages.filter((stage:any) => stage.plots).flatMap((stage:any) => {
                                                            return stage.plots.filter((plot:any) => {return plot && plot.values;}).map((plot:any) => {
                                                                return plot.values.reduce((accumulator:string, currentValue:string)=> parseFloat(accumulator) + parseFloat(currentValue))/(plot.values.length>0 ? plot.values.length : 1);
                                                            })
                                                        }),
                                                        fill: true,
                                                    },
                                                ],
                                            }}
                                            width={window.screen.width-60}
                                            height={400}
                                            options={
                                                {
                                                    maintainAspectRatio: true,
                                                    responsive: true,
                                                    scales:{
                                                        xAxes:[
                                                            {
                                                                display:true,
                                                                labelString:'Test',
                                                                ticks:{
                                                                    beginAtZero:true,
                                                                    stepSize:1,
                                                                },
                                                                gridLines: {
                                                                    offsetGridLines: false
                                                                }
                                                            }
                                                        ],
                                                        yAxes:[
                                                            {
                                                                ticks:{
                                                                    beginAtZero:true,
                                                                    stepSize:1,
                                                                    suggestedMax: 10,
                                                                }
                                                            }
                                                        ]
                                                    },
                                                }
                                            }
                                        />
                                    </div>
                                    );
                                    }else if(dataPoint.type === 'radio' ){
                                        return (
                                            <div className="sg-h-120 sg-pt-6">
                                                <h1 className="sg-text-gray-600 sg-text-lg sg-my-0 sg-text-center sg-w-full">{dataPoint.name}</h1>
                                                <div className="sg-h-96">
                                                    <Bar
                                                        type={Bar}
                                                        data={{
                                                            labels:dataPoint.stages.filter((stage:any) => {return stage && stage.plots}).flatMap((stage:any) => { 
                                                                return stage.plots.filter((plot:any) => {return plot && plot.values;}).map((plot:any) => {
                                                                    return `${stage.name},${plot.plotName}`;
                                                                });
                                                            }),
                                                            datasets: dataPoint.options.filter((option:any) => Boolean(option.label)).flatMap((option:any, optionIndex:number)=>{
                                                                return {
                                                                    label: option.label,
                                                                    backgroundColor: ['#01558c', '#06936E', '#BBD048','#193b6c', '#36abe0', '#549a8b'][optionIndex],
                                                                    data: dataPoint.stages.filter((stage:any) => {return stage && stage.plots}).flatMap((stage:any) => {
                                                                        return stage.plots.filter((plot:any) => {return plot && plot.values;}).flatMap((plot:any) => {
                                                                            return plot.values.filter((item:any) => item.key === option.value ).map((item:any) => {
                                                                                return item.value
                                                                            });
                                                                        })
                                                                    }),
                                                                    fill: true,
                                                                }
                                                            }),
                                                        }}
                                                        width={window.screen.width-60}
                                                        height={400}
                                                        options={
                                                            {
                                                                maintainAspectRatio: true,
                                                                responsive: true,
                                                                scales:{
                                                                    xAxes:[
                                                                        {
                                                                            display:true,
                                                                            labelString:'Test',
                                                                            ticks:{
                                                                                beginAtZero:true,
                                                                                stepSize:1,
                                                                            },
                                                                            gridLines: {
                                                                                offsetGridLines: false
                                                                            }
                                                                        }
                                                                    ],
                                                                    yAxes:[
                                                                        {
                                                                            ticks:{
                                                                                beginAtZero:true,
                                                                                stepSize:1,
                                                                                suggestedMax: 10,
                                                                            }
                                                                        }
                                                                    ]
                                                                },
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        );
                                    }else if(dataPoint.type==='textfield' || dataPoint.type==='textarea' || dataPoint.type==='email'){
                                        return (
                                            <div className="sg-pt-6">
                                                <h1 className="sg-text-gray-600 sg-text-lg sg-my-0 sg-text-center sg-w-full">{dataPoint.name}</h1>
                                                <div className="sg-w-full sg-divide-y sg-divide-x-0 sg-divide-sgSecondary sg-flex sg-flex-col">
                                                    { dataPoint.stages.flatMap((stage:any) => {
                                                        return stage.plots.flatMap((plot:any)=>{
                                                            return plot.values.map((value:any) => {
                                                                return (
                                                                    <div className="sg-w-full sg-py-2">
                                                                        <span className="sg-text-sgSecondary sg-font-semibold sg-px-2 sg-text-lg">{1}.</span><span className="sg-font-medium">{value}</span><span className="sg-text-sgSecondary sg-font-semibold sg-text-xl"> : </span><span className="sg-text-gray-600">{stage.name}, {plot.plotName}</span>
                                                                    </div>
                                                                );
                                                            })
                                                        })
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        );
                                    }else{
                                        
                                    }
                            })
                            : <div></div>}
                        </div>
                        <IonAlert
                            isOpen={showAlert1}
                            onDidDismiss={() => setShowAlert1(false)}
                            cssClass='my-custom-class'
                            header={'Descargar Resultatos'}
                            message={'Elige un formato'}
                            buttons={[
                                {
                                    text: 'CSV',
                                    handler: () => {
                                        console.log('Confirm XLS');
                                    }
                                },
                                {
                                    text: 'PDF',
                                    handler: () => {
                                        requestPDF();
                                    }
                                },
                                {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: blah => {
                                        console.log('Confirm Cancel: blah');
                                    }
                                },
                            ]}
                        />
                        {tourForm.form.results ? <div className="sg-w-full sg-text-center sg-flex sg-justify-center sg-pb-12">
                            <IonButton onClick={() => setShowAlert1(true)} className="sg-rounded-full sg-px-8 sg-text-lg sg-w-80 sg-uppercase sg-tracking-wider sg-block">Descargar</IonButton>
                        </div> : <div></div>}
                    </div>
                }
                { !tourForm && <IonSpinner className="sg-h-24 sg-w-24 sg-mx-auto"/> }
            </IonContent>
        </div>
        </IonPage>
    )
}

export default Results;