import { useStorage } from '@capacitor-community/react-hooks/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useHttpReq } from './useHttpReq';
import { Storage } from '@capacitor/storage';


export interface AuthState {
    dmState: boolean;
}

export function useAuth() {
    //const { get, set, remove } = useStorage();
    const { makeHttpPost } = useHttpReq();
    const userAuth = useSelector((state:any) => { return state.userAuth});
    const dispatch = useDispatch();

    const reqRegister = ():Promise<unknown> => {
        const registerCallBack = (resolve:any) => {
            console.log(resolve);
            setTimeout(resolve, 3000);
        };
        return new Promise( registerCallBack );
    }

    const reqLogin = async (username:string, pass:string):Promise<any> => {
        console.log('before post ' + username + " " + pass);
        let loginData;
        try {
            loginData = await makeHttpPost('/auth/login', {email:username, password:pass});
        } catch (e) {
            console.log(e);
            console.log(loginData);
        }
        console.log('after post ' + username + " " + pass);
        console.log(loginData);
        
        if(loginData.data.success){
            console.log(loginData);
            await Storage.set( {key:'authToken', value:loginData.data.data.token });
            return loginData.data.token;
        }else{
            let validationErrors: any = {};
            for (const error of loginData.data) {
                validationErrors[error.field] = {message: error.message}
            }
            throw validationErrors
        }
        
        
    }

    const isLogedIn = async () => {
        const {value:token} = await Storage.get({key:'authToken'});
        if(token && !userAuth.authToken){
            dispatch({ type: 'SAVE_AUTH', payload:{jwToken:token} });
        }
        return userAuth.authToken;
    }
    const reqLogout = async () => {
        if(userAuth.authToken){
            console.log('Removing Token');
            await Storage.remove({key:'authToken'});
            console.log('success');
            dispatch({ type: 'WIPE_STATE' });
            //dispatch({ type: 'CLEAR_AUTH' });
            return true;
        }
    }

    const reqPassResetEmail = async (username: string) => {
        let passResetData:any = {};
        try {
            passResetData = await makeHttpPost('/auth/reset/request', {email:username});
        } catch (error) {
            passResetData = error.response
            
        }
        if(passResetData.data.success){
            return passResetData.data;
        }else{
            if(passResetData.data.code == 'E_VALIDATE_FAILED'){
                let validationErrors: any = {};
                for (const error of passResetData.data.errors) {
                    validationErrors[error.field] = {message: error.message}
                }
                console.log(validationErrors);
                throw validationErrors;
            }
        }
    }

    return {
        reqRegister,
        reqLogin,
        isLogedIn,
        reqLogout,
        reqPassResetEmail
    };
    

}