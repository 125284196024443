import { useState, } from 'react';

export interface ValidationField {
    dmStmessageate: string;
}

export interface ValidationObject {
    
}

export function useValidation() {

    const [validations, setValidations]:any = useState<object>();

    const hasError = (key:string) => {
        return validations && validations.hasOwnProperty(key)
    }

    const hasAnyError = () => {
        return validations && Object.keys(validations).length > 0
    }

    const getErrorMessage = (key:string) => {
        return validations && validations.hasOwnProperty(key) ? validations[key].message : `Error: ${key}`; 
    }

    return {
        hasError,
        hasAnyError,
        getErrorMessage,
        validations,
        setValidations
    };
    

}