import React,{ useCallback, useContext, useEffect, useState, } from 'react';
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonImg, IonLabel, IonMenuButton, IonPage, IonSlide, IonSlides, IonSpinner, IonText, IonToolbar, isPlatform, NavContext, useIonModal, useIonToast, useIonViewDidEnter, useIonViewWillLeave, useIonAlert } from '@ionic/react';
import { useForms } from '../../hooks/useForms';
import { useParams } from 'react-router-dom';
import { checkmark, chevronBack, chevronForward, close } from 'ionicons/icons';
import SgFormField from '../../components/form_components/SgFormField';
import { useDispatch, useSelector } from 'react-redux';
import FullscreenLoader from '../../components/FullscreenLoader';
import StagePlotModal from '../../components/stage_plot_modal/StagePlotModal';
import { Conntection } from '../../redux/reducers/DeviceStatusReducer';
import { useSubmisionQueue } from '../../hooks/useSubmisionQueue';

import { Keyboard } from '@capacitor/keyboard';

const TourForm: React.FC = () => {
    let { id, form_id }:any = useParams();
    const { reqTourForm, submitFormAnswers, backUpToStorage, retriveBackUp } = useForms();
    let { addToQueue } = useSubmisionQueue();
    const [fresh, setFresh] = useState(true);
    const dispatch = useDispatch();
    const [showAlert] = useIonAlert();
    /* let [form, setForm] = useState({
        id:'',
        title:'undefined form',
        data:[],
        description:''
    }) */
    let formIndex:number = 0;

    let form:any = useSelector((state:any) => {
        if(state.forms){
            let i = 0
            for (const formItem of state.forms) {
                if(formItem.id === parseInt(form_id)){
                    formIndex=i;
                    return formItem;
                }
                i++;
            }
        }
    });

    const connection:Conntection = useSelector((state:any) => { return state.deviceStatus.connection});

    const [toastPresent, toastDismiss] = useIonToast();
    const [justHideTheLoaderAnyway, setJustHideTheLoaderAnyway] = useState(true);
    const doRefresh = () => {
        reqTourForm(id, form_id).then(resp => {
            if(!resp.formFields){
                setJustHideTheLoaderAnyway(true);
                setTimeout(()=>{
                    backToFormList();
                    toastPresent({cssClass:'sg-special-toast sg--top-8 sg-text-base sg-text-center', duration:2000, position:'top', header:'', message: '<strong>Error:</strong> Este Formulario no tiene preguntas'});
                },400)
            }else{
                if(resp.formFields.data.length){
                    resp.formFields.data = JSON.parse(resp.formFields.data)
                    // resp.formFields.stages = JSON.parse(resp.formFiels.stages)
                    resp.formFields.data.push({insert_last_slide:true})
                }
                dispatch({ type: 'SAVE_FORM', payload:{form:resp} });
            }
            //setForm(resp);
        }).catch(error => {
            console.log(error);
            backToFormList();
            setJustHideTheLoaderAnyway(true);
        });
    }

    useEffect(()=>{
        if(fresh){
            if(!form || !form.formFields){
                doRefresh();
            }else{
                if(!form.dirty){
                    backUpToStorage(form).then(()=>{
                        dispatch({ type: 'SET_FORM_DIRTY', payload:{formId:formIndex} });
                    });
                }
                console.log('form already in state obj. skipping network request');
            }
            setFresh(false);
        }
    }, [reqTourForm, fresh, setFresh, dispatch]);
    const slideOpts = {
        initialSlide: 0,
        speed: 400,
        slidesPerView: 1,
    };
    const [swiper, setSwiper] = useState<any>({});
    const init = (context: any) => {
        context.target.getSwiper().then((sw:any) => {
            setSwiper(sw);
            updatePageTicker();
        });
    };
    const [activePageNum, setActivePageNum] = useState(0);
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [lastPageNum, setLastPageNum] = useState(0);
    const [isSecondLastSlide, setIsSecondLastSlide] = useState(false);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const [isStartSlide, setIsStartSlide] = useState(false);
    const [nextButtonIcon, setNextButtonIcon]:any = useState(chevronForward);
    const [nextButtonText, setNextButtonText]:any = useState('Siguiente');
    const [isFlashDisplaying, setIsFlashDisplaying]:any = useState(false);
    const [formComplete, setformComplete]:any = useState(false);
    const [showStagesModal, setShowStagesModal]:any = useState(false);
    const [addedToQueue, setAddedToQueue]:any = useState(false);

    const [stageIndex, setStageIndex] = useState<number>(0);
    const [plotIndex, setPlotIndex] = useState<number>(0);

    const {navigate} = useContext(NavContext);


    const updatePageTicker = () => {
        swiper.allowSlideNext = true;
        setActivePageNum(swiper.activeIndex || swiper.isBeginning ? swiper.activeIndex+1 : 0);
        setActivePageIndex(swiper.activeIndex || swiper.isBeginning ? swiper.activeIndex : 0);
        setLastPageNum(swiper.slides && swiper.slides.length>0  ?  swiper.slides.length-1 : 0);
        setIsStartSlide(swiper.isBeginning);
        setIsSecondLastSlide(swiper.slides && swiper.activeIndex === swiper.slides.length-2);
        setIsLastSlide(swiper.isEnd);
        modifyForwardButtonForPage();
    };

    const nextPage = () => {
        swiper.slideNext();
    };

    const backPage = () => {
        swiper.slidePrev();
    };

    const reachedLastSlide = ():any =>{
        setIsLastSlide(true);
    }
    const reachedStartSlide = ():any =>{
        setIsStartSlide(true);
    }

    const isThisTheSecondLastSlide = (e:any):any =>{
        if(swiper.activeIndex === swiper.slides.length-2){
            console.log('Second to last');
            swiper.allowSlideNext = false;
            setIsSecondLastSlide(true);
        }
    }

    const customModalPresent = () =>{ // you could techinicaly move this present call directly into the onclick declaration but it looks gross there
        present();
    }

    const modalSelectStagePlot = (selectedStageIndex:number,selectedPlotIndex:number) => {
        setStageIndex(selectedStageIndex);
        setPlotIndex(selectedPlotIndex);
        dismiss();
    };
    const modalClose = (selectedStageIndex:number,selectedPlotIndex:number) => {
        dismiss();
    };

    const [present, dismiss] = useIonModal(StagePlotModal,{
        tourName:"form",
        stages: form && form.formFields ? JSON.parse(form.formFields.stages) : [],
        stageIndex:stageIndex,
        plotIndex:plotIndex,
        handleCloseModal:modalClose,
        handleModalConfim:modalSelectStagePlot,


    });

    const handleFormSubmit = (e:any):any => {
        //console.log(e);
        e.preventDefault();
        swiper.allowSlideNext = true;
        swiper.slideNext();
        swiper.allowSlideNext = false;
        swiper.allowSlidePrev = false;
        if( !connection.connected || connection.connectionType==="none" ){
            //dispatch({type:'ADD_TO_QUEUE', payload:{actionType:'form_submit', data:{queuedAt:Date.now(), tour_id:id, form_id:form_id, form_data:form.formFields}}})
            addToQueue({
                actionType:'form_submit',
                data:{queuedAt:Date.now(), tour_id:id, form_id:form_id, form_data:form.formFields}
            });
            setTimeout(//delay the complete notification just to make it more clear that data was actually submited
                () => {
                    setAddedToQueue(true);
                    setformComplete(true);
                    //doRefresh();
                    retriveBackUp(form_id).then((respJSON)=>{
                        if (!respJSON.value) {
                            //console.log('oh no');
                            return 0;
                        }
                        let resp = JSON.parse(respJSON.value);
                        //console.log(resp)
                        dispatch({ type: 'SAVE_FORM', payload:{form:resp} });
                    }).catch(error => console.log(error));
                },
                200
            );
        }else{
            submitFormAnswers(id, form_id, form.formFields).then((resp) => {
                setTimeout(//delay the complete notification just to make it more clear that data was actually submited
                    () => {
                        setformComplete(true);
                        doRefresh();
                    },
                    200
                );
                //console.log(resp);
            })
            .catch((error) => {
                setTimeout(//this is just here so the user can see the next slide come in and then come out so that theyre less confused why they just got sent back a slide
                    () => {
                        flashError();
                        console.log(error);
                        swiper.allowSlidePrev = true;
                        swiper.slidePrev();
                    },
                    2000
                );
            });
        }
    }

    const nextButtonClick = (e:any):any => {
        // if (swiper.isEnd) {
        //     if (stageIndex < form.formFields.stages.length - 1) {
        //         if (plotIndex < form.formFields.data.length - 3) {
        //             setPlotIndex(plotIndex + 1);
        //         } else {
        //             setPlotIndex(0);
        //             setStageIndex(stageIndex + 1);
        //         }
        //     } else if (plotIndex < form.formFields.data.length - 3) {
        //         setPlotIndex(plotIndex + 1);
        //     }
        // }
        isThisTheSecondLastSlide(e);
        let allStages = JSON.parse(form.formFields.stages)

        if((swiper.slides && swiper.activeIndex === swiper.slides.length-2 && stageIndex === allStages.length - 1 && plotIndex === allStages[allStages.length - 1].plots.length - 1) || (swiper.slides && swiper.activeIndex === swiper.slides.length - 2  && form.is_satisfaction.includes('Satisfaction'))){
            let empty = false;
            let contador = 0;
            for (let i = 0; i < form.formFields.data.length - 1; i++) {
                for (let j = 0; j < form.formFields.data[i].components.length; j++) {
                    if (form.formFields.data[i].components[j].userInput) {
                        for (let stage of form.formFields.data[i].components[j].userInput.stages) {
                          if (stage === ['']) {
                              stage= ['0'];
                              empty = true;
                              break;
                          } else{
                            contador ++;
                          }
                        }
                    } else {
                        empty = true;
                        break;
                    }
                    if (empty) break;
                }
                if (empty) break;
            }
            /* if (contador === 0) {
              showAlert({
                  header: 'Error al Completar',
                  message: 'Por favor rellene por lo menos una de las preguntas del formulario.',
                  buttons: [
                    { text: 'OK', handler: (d) => console.log('ok pressed') },
                  ],
                  onDidDismiss: (e) => console.log('did dismiss'),
                })
              return;
            } */
            handleFormSubmit(e);
        } else if (swiper.slides && swiper.activeIndex == swiper.slides.length - 2 && stageIndex < allStages.length - 1) {
            if (plotIndex < allStages[stageIndex].plots.length - 1) {
                setPlotIndex(plotIndex + 1);
                for (let i = 0; i < form.formFields.data.length - 2; i++) {
                    swiper.slidePrev();
                }
            } else {
                setPlotIndex(0);
                setStageIndex(stageIndex + 1);
                for (let i = 0; i < form.formFields.data.length - 2; i++) {
                    swiper.slidePrev();
                }
            }
        }
        else if (swiper.slides && swiper.activeIndex == swiper.slides.length - 2 && plotIndex < allStages[stageIndex].plots.length - 1) {
            setPlotIndex(plotIndex + 1);
            for (let i = 0; i < form.formFields.data.length - 2; i++) {
                swiper.slidePrev();
            }
        }
        else if(swiper.isEnd){
            exitForm();
        }else{
            nextPage();

        }
    }

    const modifyForwardButtonForPage = ():any => {
        let allStages = JSON.parse(form.formFields.stages)
        if((swiper.slides && swiper.activeIndex === swiper.slides.length-2 && stageIndex === allStages.length - 1 && plotIndex === allStages[allStages.length - 1].plots.length - 1) || (swiper.slides && swiper.activeIndex === swiper.slides.length - 2 && form.is_satisfaction.includes('Satisfaction'))){
            setNextButtonIcon(checkmark);
            setNextButtonText('Completar');
        }else if(swiper.isEnd){
            setNextButtonIcon(close);
            setNextButtonText('Cerrar');
        }else{
            setNextButtonIcon(chevronForward);
            setNextButtonText('Siguiente');

        }
    }

    const flashError = ():any => {
        setIsFlashDisplaying(true)
        setTimeout(
            () =>setIsFlashDisplaying(false),
            3000
        );
    }

    const backToFormList = useCallback(
        () => navigate(`/tours/${id}/forms/`, 'back'),
        [navigate, id]
    );

    const exitForm = useCallback(
        () => navigate('/', 'back'),
        [navigate]
    );



    const [hideControllButtons, setHideControllButtons] = useState(false);


    const keyboardFix = () =>
    {
        Keyboard.addListener('keyboardWillShow', info => {
            setHideControllButtons(true);
        });

        Keyboard.addListener('keyboardDidHide', () => {
            setHideControllButtons(false);
        });
    }

    useIonViewDidEnter(() => {
        if (isPlatform('hybrid')) {
            keyboardFix();
        }
    });

    useIonViewWillLeave(() => {
        if (isPlatform('hybrid')) {
            Keyboard.removeAllListeners();
        }
    });

    //console.log(form)

    return (
        <IonPage>
            { isFlashDisplaying &&
            <div className="sg-fixed sg-top-0 sg-left-0 sg-w-screen sg-h-16 sg-flex sg-items-center sg-justify-center sg-px-4 sg-bg-red-300 sg-border-red-800 sg-border-4 sg-border-solid sg-text-red-800 sg-z-50 sg-rounded-b-2xl">
                <span><strong>Error:</strong> No se pudieron guardar las respuestas.</span>
            </div>
            }
            <IonHeader className="sg-border-0 sg-border-b-4 sg-border-gradient-br-sgPrimary-sgSecondary-white sg-border-transparent sg-border-solid">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" text="Atrás"/>
                        </IonButtons>
                        <IonImg className="logo_img" src={'/assets/images/clean_logo.png'} alt="logo" style={{width:'7.5em'}}/>
                        {/* <IonTitle>{form && form.title ? form.title : ''}</IonTitle> */}
                        <IonMenuButton slot="end"></IonMenuButton>
                    </IonToolbar>
                </IonHeader>

            {form &&

                <IonContent fullscreen>
                    {(!swiper.isEnd && !form.is_satisfaction.includes('Satisfacción')) ?
                        <IonHeader>
                            <div className="sg-flex sg-justify-between sg-w-full sg-px-2 sg-font-semibold sg-text-gray-500 sg-uppercase">
                                <span className="sg-text-base">Página <span className="sg-text-sgSecondary">{activePageNum ? activePageNum : '?'}</span>/{lastPageNum ? lastPageNum : '?'}</span>
                                <span className={"sg-text-base" + (form.is_satisfaction.includes('Satisfaction') ? ' sg-invisible' : ' sg-visible')}>Selecciona una opción</span>
                            </div>
                            <div className={"sg-flex sg-flex-wrap sg-justify-between sg-w-full  sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-pt-4 sg-pb-2 sg-px-2"}>
                                <span className={"sg-text-white sg-text-left sg-font-bold sg-text-base" + (form.is_satisfaction.includes('Satisfaction') ? ' sg-invisible' : ' sg-visible')}>
                                {form.formFields.stages ? form.formFields.stages[stageIndex].name : ''}
                                </span>
                                <div className={"sg-text-white sg-text-center sg-font-bold sg-text-base"}>
                                  <span className="sg-text-base">Stage: {JSON.parse(form.formFields.stages)[stageIndex].name}</span>
                                </div>
                                    { form.formFields.stages &&
                                    <IonButtons className={"sg-text-white sg-text-right sg-font-bold sg-text-base" + (form.is_satisfaction.includes('Satisfaction') ? ' sg-invisible' : ' sg-visible')}>
                                        <IonLabel className="sg-pr-2" slot="end">Plot: {JSON.parse(form.formFields.stages)[stageIndex].plots[plotIndex].name}</IonLabel>
                                        <span color="transparent" className="sg-relative sg-rounded-full sg-bg-white sg-p-2 sg-w-6 sg-h-6" onClick={customModalPresent}>
                                            <IonImg src={'/assets/icon/right_left_arrows.svg'} alt="logo" className="sg-absolute sg-top-1.5 sg-left-1 sg-h-8/12 sg-w-8/12"/>
                                        </span>
                                    </IonButtons>
                                    }

                                  <div className={"sg-text-white sg-text-center sg-font-bold sg-text-base sg-w-full sg-p-2"}>
                                    <span className="sg-text-base">{form.title}</span>
                                  </div>
                            </div>
                        </IonHeader>
                        :
                        <IonHeader>
                            <div className={"sg-flex sg-flex-wrap sg-justify-between sg-w-full  sg-bg-gradient-to-br sg-from-sgBlue sg-to-sgSecondary sg-pt-4 sg-pb-2 sg-px-2"}>
                                  <div className={"sg-text-white sg-text-center sg-font-bold sg-text-base sg-w-full sg-p-2"}>
                                    <span className="sg-text-base">{form.title}</span>
                                  </div>
                            </div>
                        </IonHeader>
                    }
                    <form action="" className="sg-contents" onSubmit={handleFormSubmit}>
                        <IonSlides options={slideOpts} style={{height:!swiper.isEnd ? '94%': '100%', overflow: "inherit"}} onIonSlideDidChange={updatePageTicker} onIonSlidesDidLoad={init} onIonSlideReachStart={reachedStartSlide} onIonSlideNextEnd={isThisTheSecondLastSlide}>
                            {
                            (form.formFields && form.formFields.data) && form.formFields.data.map((form_page:any, pageIndex:number) => {
                                if(form_page.insert_last_slide){
                                    return (

                                        <IonSlide>
                                            <div className="sg-relative sg-w-full sg-h-full sg-transparent-gradient-bg sg-bg-opacity-100">
                                                <IonImg src={'/assets/images/form_complete_bg.png'} className="sg-absolute sg-top-0 sg-left-0 sg-h-full sg-w-full sg-z-0 sg-object-cover sg-filter sg-invert sg-contrast-125 sg-brightness-125 sg-grayscale"/>
                                                <div className="sg-absolute sg-z-10 sg-transparent-gradient-bg sg-h-full sg-w-screen">
                                                    {(!addedToQueue && !formComplete) &&
                                                        <div className="
                                                        sg-w-10/12 sg-mx-auto sg-max-w-xl
                                                        sg-rounded-2xl sg-shadow-xl
                                                        sg-bg-white
                                                        sg-absolute sg-z-10
                                                        sg-top-1/2 sg-left-1/2
                                                        sg-transform sg--translate-y-3/4 sg--translate-x-1/2
                                                        sg-text-left
                                                        sg-flex sg-flex-col sg-justify-center sg-items-center
                                                        sg-p-10
                                                        sg-space-y-4
                                                        ">
                                                            <IonImg src={'/assets/images/grain.png'} className="sg-h-24 sg-w-auto sg-object-fill"/>
                                                            <IonSpinner className="sg-h-24 sg-w-24" color="white"/>
                                                            <p className="sg-text-xl sg-absolute sg-z-10 sg--bottom-16 sg-text-white">Guardando...</p>
                                                        </div>
                                                    }
                                                    {(!addedToQueue && formComplete) &&
                                                        <div className="
                                                        sg-w-10/12 sg-mx-auto sg-max-w-xl
                                                        sg-rounded-2xl sg-shadow-xl
                                                        sg-bg-white
                                                        sg-absolute
                                                        sg-top-1/2 sg-left-1/2
                                                        sg-transform sg--translate-y-3/4 sg--translate-x-1/2
                                                        sg-text-left
                                                        sg-flex sg-flex-col sg-justify-center sg-items-center
                                                        sg-p-10
                                                        sg-space-y-4
                                                        ">
                                                            <IonImg src={'/assets/images/grain.png'} className="sg-h-24 sg-w-auto sg-object-fill"/>
                                                            <IonText className="sg-text-xl"><strong>Felicidades</strong> concluiste el formulario <strong>{form.title}</strong> esperamos que haya sido de tu agrado.</IonText>
                                                            <IonButton className="sg-rounded-full sg-uppercase sg-text-lg" onClick={exitForm}>Aceptar</IonButton>
                                                        </div>
                                                    }
                                                    {(addedToQueue && formComplete) &&
                                                        <div className="
                                                        sg-w-10/12 sg-mx-auto sg-max-w-xl
                                                        sg-rounded-2xl sg-shadow-xl
                                                        sg-bg-white
                                                        sg-absolute
                                                        sg-top-1/2 sg-left-1/2
                                                        sg-transform sg--translate-y-3/4 sg--translate-x-1/2
                                                        sg-text-left
                                                        sg-flex sg-flex-col sg-justify-center sg-items-center
                                                        sg-p-10
                                                        sg-space-y-4
                                                        ">
                                                            <IonText className="sg-text-xl"><strong>Agregado a la cola</strong> se enviará cuando haya una conexión estable.</IonText>
                                                            <IonButton className="sg-rounded-full sg-uppercase sg-text-lg" onClick={exitForm}>Aceptar</IonButton>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </IonSlide>
                                    )
                                }
                                let noQuestions = true;
                                let questionCompoments;
                                if (form_page.key != 'submit') {
                                    questionCompoments = form_page.components.map((field:any, compIndex:number) => {
                                        let dumbComparison = `[${stageIndex}][${plotIndex}]`;
                                        if(!field.plots || field.plots.includes(dumbComparison)){
                                            noQuestions = false;
                                            return(
                                                <SgFormField field={field} formIndex={formIndex} componentIndex={compIndex} pageIndex={pageIndex} stageIndex={stageIndex} plotIndex={plotIndex} is_satisfaction={form.is_satisfaction} descripcion={form.descripcion}/>
                                            );
                                        } else { 
                                            console.log('not met')
                                        }
                                    });
                                    console.log(questionCompoments)
                                } else questionCompoments = [];
                                return (
                                    <IonSlide key={form_page.key}>
                                        <pre>{JSON.stringify(form_page, null, 4)}</pre>
                                        <div className="sg-flex sg-flex-col sg-w-full sg-h-full">
                                            <div className="sg-flex sg-w-full sg-justify-center sg-items-center sg-p-2 sg-h-32 sg-pb-8">

                                                <div className="sg-w-2/12">
                                                    <div className="sg-w-12 sg-h-12 sg-bg-green-600" style={
                                                        {
                                                        mask:'url("/assets/icon/fontawesome/svgs/regular/' + (form_page.sgIcon ? form_page.sgIcon.key : 'address-book') + '.svg")',
                                                        maskRepeat:'no-repeat',
                                                        maskSize:'contain',
                                                        WebkitMask:'url("/assets/icon/fontawesome/svgs/regular/' + (form_page.sgIcon ? form_page.sgIcon.key : 'address-book') + '.svg")',
                                                        WebkitMaskRepeat:'no-repeat',
                                                        WebkitMaskSize:'contain',
                                                        }
                                                        }>
                                                    </div>
                                                </div>
                                                {/* <ReactSVG src={'/assets/icon/fontawesome/svgs/regular/' + (form_page.sgIcon ? form_page.sgIcon.key : 'address-book') + '.svg'}></ReactSVG> */}
                                                {/* <object className="sg-w-12" data={'/assets/icon/fontawesome/svgs/regular/' + (form_page.sgIcon ? form_page.sgIcon.key : 'address-book') + '.svg'} type="image/svg+xml"></object> */}
                                                <span className="sg-w-9/12 sg-text-left sg-font-semibold sg-text-2xl pt-4">{form_page.title && form_page.title}</span>
                                            </div>
                                            <div className="sg-w-screen sg-text-left sg-pb-64 sg-flex sg-flex-col sg-justify-center sg-items-center sg-text-gray-600 sg-relative">
    
                                                { !noQuestions && questionCompoments }

                                                {/* <pre className="sg-overflow-scroll sg-h-28 sg-w-full sg-hidden">
                                                    {JSON.stringify(form_page.components, null, 4)}
                                                </pre> */}
                                            </div>
                                        </div>
                                    </IonSlide>
                                );
                            })
                            }
                        </IonSlides>
                    {(!swiper.isEnd && !hideControllButtons) &&
                        <div className="sg-fixed sg-w-full sg-flex sg-items-baseline sg-justify-between sg-bottom-4 sg-space-x-8 sg-px-4 sg-z-50">
                            <IonImg src={'/assets/images/syngenta_logo.png'} alt="logo" style={{width:'120px'}}/>
                            <div className="sg-flex sg-space-x-8 sg-items-baseline">
                                { !isLastSlide && <div>
                                    <div id="" className={"sg-swiper-button-prev sg-text-center sg-py-2 sg-h-16 sg-w-16 sg-rounded-full sg-shadow-lg  sg-border-solid sg-border-4 sg-cursor-pointer" + (isStartSlide ? 'sg-bg-white sg-text-gray-200 sg-border-gray-200' : 'sg-bg-white sg-text-gray-500' )} onClick={backPage}>
                                        <IonIcon className="sg-text-4xl" icon={chevronBack} />
                                    </div>
                                    <p className="sg-text-gray-500 sg-uppercase sg-mt-3 sg-py-0">Anterior</p>
                                </div>}
                                <div>
                                    <div id="" className="sg-swiper-button-next sg-text-center sg-py-4 sg-h-20 sg-w-20 sg-rounded-full sg-shadow-lg sg-text-white sg-bg-gradient-to-br sg-from-sgPrimary sg-to-sgSecondary sg-cursor-pointer" onClick={nextButtonClick}>
                                        <IonIcon className="sg-text-5xl" icon={nextButtonIcon} />
                                    </div>
                                    <p className="sg-text-sgSecondary sg-uppercase sg-mt-3 sg-py-0 sg-text-center">{nextButtonText}</p>
                                </div>
                                {/* <input type="submit" value="submit"/> */}
                            </div>
                        </div>
                    }
                    </form>
                </IonContent>

            }
            { (!form && justHideTheLoaderAnyway) && <FullscreenLoader/> }
            {/* { form && form.formFields.stages &&
                <StagePlotModal tourName={"form"} stages={form.formFields.stages} show={{show:showStagesModal, setShow:setShowStagesModal}} selectedStagePlot={
                    {
                        stage:{
                            stageIndex:stageIndex, setStageIndex:setStageIndex
                        },
                        plot:{
                            plotIndex:plotIndex,
                            setPlotIndex:setPlotIndex,
                        }
                    }
                }
                currentQuestionPlots={{}}/>
            } */}
        </IonPage>
    );
};

export default TourForm;
