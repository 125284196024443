import { IonIcon, IonProgressBar } from '@ionic/react';
import { download, } from 'ionicons/icons';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForms } from '../../hooks/useForms';
import './FormListItem.css';
interface ContainerProps {
    tourId:number;
    form:any;
    showIcon:boolean;
}

const HistoricalFormsListItem: React.FC<ContainerProps> = ({ tourId, form, showIcon,}) => {
    const { reqTourForm } = useForms();

    const [downloading , setDownloading] = useState(false);
    const [disableDl , setDisableDl] = useState(false);
    const dispatch = useDispatch();
    /* let [form, setForm] = useState({
        id:'',
        title:'undefined form',
        data:[],
        description:''
    }) */

    const downloadTour = () => {
        setDownloading(true);
        reqTourForm(tourId, form.id).then((resp:any) => {
            if(resp.formFields.data.length){
                resp.formFields.data.push({insert_last_slide:true})
            }
            dispatch({ type: 'SAVE_FORM_FIELDS', payload:{form:resp}, formId:form.id });
            //setForm(resp);
            setDownloading(false);
            setDisableDl(true);
        }).catch((error:any) => {
            console.log(error);
        });
    }

    const doNothing = () => {

    }

    return (
            <div className="sg-w-full sg-text-left">
                <div className="row">
                    <Link to={`/results/${form.id}`} className={showIcon ? "sg-no-underline sg-w-8/12" : "sg-no-underline sg-w-full"}>
                        <div>
                            <h1 className="sg-text-sgBlue sg-font-bold">{form.title}</h1>
                        </div>
                        <div>
                            <p className="sg-text-gray-500 description_text">{form.description}</p>
                        </div>
                    {/* <div className="sg-w-8/12">
                        <div className="sg-w-full">
                            <div className="sg-w-full pr-1">
                                <Link to={`/tours/${tourId}/forms/${form.id}`} className="sg-no-underline">
                                    <span className={"sg-text-sgBlue sg-text-2xl sg-no-underline sg-font-bold sg-min-h-6 sg-min-w-full sg-block" + (form.title ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{form.title}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="sg-w-full">
                            <Link to={`/tours/${tourId}/forms/${form.id}`} className="sg-no-underline">
                                <p className={"sg-my-2 sg-text-gray-500 sg-text-base sg-min-h-6 sg-min-w-full " + (form.description ? '' : 'sg-animate-pulse sg-bg-gray-200 sg-rounded')}>{form.description}</p>
                            </Link>
                        </div>
                    </div> */}
                    </Link>
                    {showIcon ? <div className="sg-text-gray-500 sg-flex sg-items-center sg-w-3/12">
                        <div className="sg-text-center sg-w-20" onClick={!disableDl ? downloadTour : doNothing}>
                            <IonIcon className="sg-h-6 sg-w-6" icon={download}></IonIcon>
                            <p className="sg-text-gray-800 sg-my-0 sg-text-sm">Download</p>
                            {downloading && <IonProgressBar type="indeterminate"></IonProgressBar>}
                        </div>
                        {/* <Link to={`/tours/${tourId}/forms/${form.id}`} className="sg-text-center sg-w-20 sg-no-underline sg-text-gray-500 ">
                            <IonIcon className="sg-h-6 sg-w-6" icon={eye}></IonIcon>
                            <p className="sg-text-gray-800 sg-my-0 sg-text-sm">View</p>
                        </Link> */}
                    </div> : <div></div>}
                </div>
            </div>
    );
};

export default HistoricalFormsListItem;