import { useHttpReq } from './useHttpReq';
import { Storage } from '@capacitor/storage';

export function useContact() {
    const { makeHttpGet, } = useHttpReq();

    const reqUserContactCards = async ():Promise<any> => { 
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let cardsData = await makeHttpGet(`/contacts`, authHeader);
        let cards = cardsData.data.data;
        
        return cards
    }

    return {
        reqUserContactCards,
    };
    

}