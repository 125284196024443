import React, { useEffect } from "react";
import { IonLabel, IonRadio, IonRadioGroup, } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";

//type TextFieldTypes = 'date' | 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'time' | 'week' | 'month' | 'datetime-local';

export interface SgFormFieldProps {
    field:any;
    checkBox:any;
    props:any;
    formIndex:number;
    pageIndex:number;
    componentIndex:number;
    stageIndex:number;
    plotIndex:number;
    is_satisfaction:any;
    descripcion:any;

}

let lastPlotI = 0;
let lastStageI = 0;

const SgRadioGroup: React.FC<any> = ({
    field,
    checkBox,
    formIndex,
    pageIndex,
    componentIndex,
    stageIndex,
    plotIndex,
    is_satisfaction,
    descripcion,
    ...props
    }) => {
        let radioBtnVal:any = useSelector((state:any) => {
            try{
                //console.log(`[${componentIndex}][${stageIndex}][${plotIndex}]`);
                //console.log({name:'SgRadioGroup',plotIndex:plotIndex});
                return state.forms[formIndex].formFields.data[pageIndex].components[componentIndex].userInput.stages[stageIndex][plotIndex].value;
            }
            catch(e){
                //console.log(e);
                return 0;
            }
        });

        lastStageI = stageIndex;
        lastPlotI = plotIndex;


        const dispatch = useDispatch();
        const updateRadio = (value:any):any => {
            if(stageIndex === lastStageI && plotIndex === lastPlotI){
                if(value !== undefined){
                    //console.log({name:'SgRadioGroup',plotIndex:plotIndex});
                    dispatch({ type: 'UPDATE_FIELD', payload:{value:value, field:field.tema}, formId:formIndex, pageId:pageIndex, componentId:componentIndex, stageIndex:stageIndex, plotIndex:plotIndex });
                    console.log("radioBtnVal",radioBtnVal);
                    console.log("field",field);
                }
            }
            //lastStageI = stageIndex;
            //lastPlotI = plotIndex;
        };


        //console.log(''+radioBtnVal);

        return(
            <div className="sg-px-4 sg-max-w-md sg-mx-auto sg-w-full sg-text-left">
                
                <label htmlFor={field.key} className="sg-font-semibold">{field.descripcion}</label>
                {/* <IonRadioGroup className={"sg-py-4 sg-w-full sg-grid sg-gap-4" + (( field.values && field.values.length%2===0 ) ? ' sg-grid-cols-2' : ' sg-grid-cols-1 sg-px-12') } onIonChange={(event) => updateRadio(event.detail.value)}  value={''+radioBtnVal} allowEmptySelection={true}> */}
                <IonRadioGroup className="sg-py-4 sg-w-full sg-flex sg-flex-wrap sg-justify-center" onIonChange={(event) => updateRadio(event.detail.value)}  value={''+radioBtnVal} allowEmptySelection={true}>
                { field.values.map((radioBtn:any) => {
                    return(
                        <div className="sg-h-16 sg-w-full xs:sg-w-1/2 sg-p-1" key={radioBtn.value}>
                            <div className="sg-w-full sg-h-full sg-shadow-xl sg-rounded-xl sg-bg-white sg-relative sg-p-2">
                                <IonLabel>{radioBtn.label}</IonLabel>
                                <IonRadio className="sg-absolute sg-top-2 sg-right-2" name={field.key} value={''+radioBtn.value} mode="md" />
                                {/* <p className="sg-w-full sg-text-sm sg-text-gray-500">{`[${pageIndex}][${stageIndex}][${plotIndex}]=${radioBtnVal}`}</p>  */}
                            </div>
                        </div>
                    );
                })}
                </IonRadioGroup>
                {/* <pre className="sg-overflow-scroll sg-w-full sg-h-56">
                    {JSON.stringify(field, null, 4)}
                </pre> */}
            </div>
        );
};

export default SgRadioGroup;
