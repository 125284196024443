import React from 'react';

interface MailToProps {
    email:string,
    subject?:string,
    body?:string,
    children:any,
}

const MailTo: React.FC<MailToProps> = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a className="sg-text-current sg-w-9/12" style={{textDecoration:'inherit'}} href={`mailto:${email}${params}`}>{children}</a>;
};

export default MailTo;