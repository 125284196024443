//import { useStorage } from '@ionic/react-hooks/storage/useStorage';
const axios = require('axios').default;


const BASEURL = (process.env.REACT_APP_BASE_URL || '') + (process.env.REACT_APP_API_URI || '');
const axiosInstance = axios.create({
    baseURL: BASEURL,
    timeout: 10000,
    headers: {}
});


export interface httpHookData {
    dmState: boolean;
}

export function useHttpReq() {

    //const workingConnection:boolean = useSelector((state:any) => { return (!['none'].includes(state.deviceStatus.connection.connectionType) && state.deviceStatus.connection.connected) });
    
    //const { get, set, getKeys, remove } = useStorage();
    const makeHttpGet = (endPoint:string, authHeader:object|null ):any => {
        console.log('GET to: '+ BASEURL+endPoint);
        return axiosInstance.get(endPoint, { headers: authHeader});
    }

    const makeHttpPost = (endPoint:string, data:object, authHeader={} ) => {
        console.log('POST to: '+ BASEURL+endPoint);
        return axiosInstance.post(endPoint, data, { crossDomain: true, headers: authHeader});
    }

    const makeHttpPut = (endPoint:string, data:object, authHeader:object|null ) => {
        console.log('PUT to: '+ BASEURL+endPoint);
        return axiosInstance.put(endPoint, data, { crossDomain: true, headers: authHeader});
    }
    const makeHttpPutLongerTimeOut = (endPoint:string, data:object, authHeader:object|null, config:object={} ) => {
        console.log('PUT to: '+ BASEURL+endPoint);
        return axiosInstance.put(endPoint, data, { crossDomain: true, headers: authHeader, ...config });
    }

    return {
        makeHttpGet,
        makeHttpPost,
        makeHttpPut,
        makeHttpPutLongerTimeOut,
    };
    

}