import React,{ useState, } from 'react';
import {IonContent, IonInput, IonLabel, IonPage, IonProgressBar, IonText, IonImg} from '@ionic/react';
import { IonCard, IonCardContent, IonIcon, IonButton } from '@ionic/react';
import './Login.css';

//import { useAuth } from '../../hooks/useAuth';
import { personAddOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';

/* import { string } from 'yup';
import { VIonInputProps } from '../../components/form_components/VIonInput'; */
// import { useValidation } from '../../hooks/useValidation';


/* type Inputs = {
    fullName: string,
    email: string,
    password: string,
    c_password: string,
}; */

const Register: React.FC = () => {
    // const { hasAnyError, getErrorMessage, validations, setValidations } = useValidation();
    //const { reqRegister } = useAuth();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>();
    const [name, setName] = useState<string>();
    const [pass, setPass] = useState<string>();
    const [cPass, setCPass] = useState<string>();
    
    /* const formFields: VIonInputProps[] = [
        {
            name: "email",
            component: <IonInput type="email" />,
            label: "Email",
            validation: string().required().email(),
        },
        {
            name: "fullName",
            label: "Full Name",
            validation: string().required().min(5).max(32),
        },
        {
            name: "password",
            component: <IonInput type="password" clearOnEdit={false} />,
            label: "Password",
            validation: string().required().min(8),
        },
        {
            name: "c_password",
            component: <IonInput type="password" clearOnEdit={false} />,
            label: "Conforim Password",
            validation: string().required().min(8),
        },
    ]; */

    
    /* const handleSubmit = (data:any) => {
        console.log(data);
        //tryRegister();
    };
    const tryRegister = async () => {
        setLoading(true);
        await reqRegister();
        setLoading(false);
    } */

    return (
        <IonPage className="auth-page">
            <IonContent fullscreen>
                <div style={{paddingTop:'clamp(16vw, 300px, 20vh)'}}>
                    <IonImg className="logo_img" src={'/assets/images/data_logo.png'} alt="logo" style={{width:'15em'}}/>
                    <IonText>
                        <h1 style={{textAlign:'center'}}>Register</h1>
                    </IonText>
                    <IonCard>
                        <IonCardContent>
                            <form>
                            <IonLabel>Full Name</IonLabel>
                            <IonInput type="text" value={name} placeholder="Enter Input" onIonChange={e => setName(e.detail.value!)}></IonInput>
                            <IonLabel>Email</IonLabel>
                            <IonInput type="email" value={email} placeholder="Enter Input" onIonChange={e => setEmail(e.detail.value!)}></IonInput>
                            <IonLabel>Password</IonLabel>
                            <IonInput type="password" value={pass} placeholder="Enter Input" onIonChange={e => setPass(e.detail.value!)} clearOnEdit={false}></IonInput>
                            <IonLabel>Confirm Parssword</IonLabel>
                            <IonInput type="password" value={cPass} placeholder="Enter Input" onIonChange={e => setCPass(e.detail.value!)} clearOnEdit={false}></IonInput>
                            <IonButton color="primary" expand="block" onClick={async (e) => {await console.log('registered')}}>
                                <IonText>Register</IonText>
                                <IonIcon slot="end" icon={personAddOutline} /> 
                            </IonButton>
                            </form>
                            {/* { hasAnyError() ? <IonItem lines="none"><IonText className="validation-error">The username or password you entered is incorect</IonText></IonItem> : '' } */}
                        </IonCardContent>
                        { loading ? <IonProgressBar type="indeterminate"></IonProgressBar> : null}
                        
                    </IonCard>
                </div>
                <div style={{textAlign:'center'}}>
                    <IonText>
                        Already have an account? <Link to="/auth/login">Login</Link>
                    </IonText>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Register;
