import React, { useCallback, useEffect, useState } from 'react';
import {
    IonApp,
    IonButton,
    IonChip,
    IonIcon,
    IonText,
    IonToast,
} from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Theme global css */
import './theme/custom.css';
import './theme/tailwindcss_built.css';

import AuthRoot from './pages/auth/AuthRoot';
import SynSplitPane from './pages/SynSplitPane';
import { useDispatch, useSelector } from 'react-redux';

import { useAuth } from './hooks/useAuth';

import { ConnectionStatus, Network } from '@capacitor/network';
import { close, cloudOffline } from 'ionicons/icons';
import { useSubmisionQueue } from './hooks/useSubmisionQueue';
import { CompleteStatus } from './redux/reducers/DeviceStatusReducer';

const App: React.FC = () => {
    const dispatch = useDispatch();
    let { isLogedIn } = useAuth();
    let { processQueue, } = useSubmisionQueue();
    const userAuth = useSelector((state:any) => { return state.userAuth});
    
    //const [networkState, setNetworkState] = useState("offline");
    const [noConectionDismised, setNoConectionDismised] = useState(false);
    const [fresh, setFresh] = useState(true);


    //const deviceStatus:CompleteStatus = useSelector((state:any) => { return state.deviceStatus});
    const workingConnection:boolean = useSelector((state:any) => { return (!['none'].includes(state.deviceStatus.connection.connectionType) && state.deviceStatus.connection.connected) });
    
    Network.addListener("networkStatusChange", (status:ConnectionStatus) => {
        //setNetworkState(status.connectionType);
        dispatch({ type: 'SAVE_CONECTION_DATA', payload:{...status} });
        if(status.connected){
            processQueue().catch(e => console.log(e));
        }
    });

    const firstPassNetWorkCheck = useCallback(()=>{
        Network.getStatus().then((status:ConnectionStatus)=>{
            console.log(status);
            //setNetworkState(status.connectionType);
            dispatch({ type: 'SAVE_CONECTION_DATA', payload:{...status} });
            
            if(status.connected){
                processQueue().catch(e => console.log(e));
            }
        });
    }, [dispatch,processQueue]);
    
    useEffect(() => {
        if(fresh){
            firstPassNetWorkCheck();
        }
        setFresh(false);
    }, [firstPassNetWorkCheck, fresh]);
    isLogedIn();
    //console.log(userAuth.authToken);
    return (
    <IonApp className={ 'sg-transition-all sg-duration-200 sg-shadow-lg ' + ( !workingConnection ? 'sg-top-8 sg-rounded-xl' : 'sg-top-0 sg-rounded-none') }>
        <IonToast
            isOpen={ ( !workingConnection && !noConectionDismised ) }
            onDidDismiss={() => setNoConectionDismised(true)}
            position="top"
            cssClass="sg-font-normal sg-text-base sg-text-center" //text-center doesnt quite align the message to the center of the toast becuase of the close button.
            //the message needs left padding
            message="           Sin conexión." //this is a really dumb way of adding left padding to the message but ionic has forced my hand
            duration={10000}
            color="dark"
            buttons={[
            {
                icon:close,
                role: 'cancel',
                handler: () => {
                //console.log('Cancel clicked');
                }
            }
            ]}
        />
        { !workingConnection && 
            <div className="sg-w-full sg-bg-gray-100 sg-text-gray-500 dark:sg-bg-gray-900 dark:sg-text-white sg-h-14 sg-px-4 sg-fixed sg-top-0 sg-z-50">
                <div className="sg-h-8 sg-flex sg-items-center sg-space-x-2 sg-w-full sg-justify-center">
                    <IonText className="sg-text-sm sg-font-semibold">No conection </IonText>
                    <IonIcon icon={cloudOffline} className="sg-text-sm sg-text-orange-600" size="small"></IonIcon>
                </div>
            </div>
        }
        {/* <div className="sg-fixed sg-z-50">
            <IonButton onClick={(e:any)=>{processQueue().catch(e => console.log(e))}}>RUN</IonButton>
        </div> */}
        { userAuth.authToken ? <SynSplitPane />: <AuthRoot/>}
    </IonApp>
    );
}

export default App;
