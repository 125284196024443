import { useHttpReq } from './useHttpReq';
import { Storage } from '@capacitor/storage';
export interface ProfileObject {
    id?:string;
    email?:string;
    status?:string;
    pfp_path:string;
    username:string;
    name:string;
    country:string;
    phone:string;
    roles:Array<any>;
    __meta__?:any;

}

export function useProfile() {
    const { makeHttpGet, makeHttpPutLongerTimeOut } = useHttpReq();

    const reqProfile = async (id:number):Promise<ProfileObject> => {
        
        let profileData = await makeHttpGet(`/users/${id}/profile`, null);
        let user:ProfileObject = {
            ...profileData.data.data
        }
        //user.pfp_path = process.env.REACT_APP_BASE_URL + '/' + user.pfp_path;
        
        return user
        /* if(profileData.data){
            return profileData.data.token;
        }else{
            let validationErrors: any = {};
            for (const error of profileData.data) {
                validationErrors[error.field] = {message: error.message}
            }
            throw validationErrors
        } */
        
        
    }
    const reqSelfProfile = async ():Promise<ProfileObject> => {
        
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let profileData = await makeHttpGet(`/users/self/profile`, authHeader);
        let user:ProfileObject = {
            ...profileData.data.data
        }
        //user.pfp_path = process.env.REACT_APP_BASE_URL + '/' + user.pfp_path;
        return user
        /* if(profileData.data){
            return profileData.data.token;
        }else{
            let validationErrors: any = {};
            for (const error of profileData.data) {
                validationErrors[error.field] = {message: error.message}
            }
            throw validationErrors
        } */
        
        
    }
    const updateSelfProfile = async (data:any = {}, isNewPfp:boolean):Promise<ProfileObject> => {
        
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let profileData = await makeHttpPutLongerTimeOut(`/users/self/profile`, {...data, is_new_pfp:isNewPfp}, authHeader,  {timeout:20000});
        
        return profileData.data
        /* if(profileData.data){
            return profileData.data.token;
        }else{
            let validationErrors: any = {};
            for (const error of profileData.data) {
                validationErrors[error.field] = {message: error.message}
            }
            throw validationErrors
        } */
        
        
    }

    /* const isLogedIn = async () => {
        const token = await get( 'authToken' );
        console.log('Token : ', token);
        if(token && !userAuth.authToken){
            console.log(userAuth.authToken);
            dispatch({ type: 'SAVE_AUTH', payload:{jwToken:token} });
        }
        return userAuth.authToken;
    } */

    const reqMyTours = async ():Promise<any> => {
        
        const authToken = await Storage.get({key:'authToken'});
        
        let authHeader = {'Authorization': `Bearer ${authToken.value}`}

        let profileData = await makeHttpGet(`/users/self/tours`, authHeader);
        let user:ProfileObject = profileData.data.data
        
        return user
    }


    return {
        reqProfile,
        reqSelfProfile,
        updateSelfProfile,
        reqMyTours,
    };
    

}