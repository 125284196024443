import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CreateNewBackedUpStore from './redux/stores/Store'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { StatusBar, StatusBarStyle } from '@capacitor/status-bar';

import { Provider } from 'react-redux';
import { isPlatform } from '@ionic/react';

import { Keyboard } from '@capacitor/keyboard';

CreateNewBackedUpStore().then((store:any)=>{
    if (isPlatform('hybrid')) {

        StatusBar.show().then(()=>{
            /* if (isPlatform('ios')) {

                Keyboard.setScroll({isDisabled:false}).then(()=>{
                    // @ts-ignore
                    Keyboard.setResizeMode({mode:"none"}).then(()=>{ // for some reason typescript throws an error even though 'none' is a valid mode
                        renderRoot(store);
                    });
                });
            }else{ */
                renderRoot(store);
            /* } */
        });

    }else{
        renderRoot(store);
    }
});

const renderRoot = (store:any) => {
    ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById('root'));
}

// Call the element loader after the app has been rendered the first time
defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
